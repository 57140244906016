import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guard/auth.guard';
import { NotLoggedInGuard } from './shared/guard/not-logged-in.guard';
import { RedirectGuard } from './shared/guard/redirect.guard';
import { AppTemplateComponent } from './template/app-template/app-template.component';
import { HomeTemplateComponent } from './template/home-template/home-template.component';
import { LoginTemplateComponent } from './template/login-template/login-template.component';

export const MAIN_PAGE_ROUTER_LINK = '/home';

//*Rotas telas gerais sem footer ou header*//
const appTemplateRoutes: Routes = [
  {
    path: '',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: '/login',
    },
  },
  {
    path: 'skip',
    redirectTo: MAIN_PAGE_ROUTER_LINK,
    pathMatch: 'full',
  },
  {
    path: 'broker',
    loadChildren: () => import('./pages/broker/broker-routing.module').then((m) => m.BrokerRoutingModule),
  },
  {
    path: 'store',
    loadChildren: () => import('./pages/store/store-routing.module').then((m) => m.StoreRoutingModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile-routing.module').then((m) => m.ProfileRoutingModule),
  },
  {
    path: 'experience',
    loadChildren: () => import('./pages/experience/experience-routing.module').then((m) => m.ExperienceRoutingModule),
  },
  {
    path: '',
    loadChildren: () => import('./pages/chat/chat-routing.module').then((m) => m.ChatRoutingModule),
  },
];

//*Rotas telas com limitação de tela 350px exemplos: login register-user forgot-password*//
const loginTemplateRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/public/public-routing.module').then((m) => m.PublicRoutingModule),
  },
  {
    path: 'register-user',
    loadChildren: () => import('./pages/public/register-user/register-user.module').then((m) => m.RegisterUserModule),
  },
];

//*Rotas de Erro*//
const errorRoutes: Routes = [
  {
    path: '**',
    loadChildren: () => import('./pages/error/error.module').then((m) => m.ErrorModule),
  },
];

//*Rotas telas que mostra o header*//
const HomeTemplateRoutes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/public/home/home.module').then((m) => m.HomeModule),
    // canActivate: [RolesGuard],
    // data: {roles: [ROLE_LISTA_USUARIO]},
  },
  {
    path: 'home-store',
    loadChildren: () => import('./pages/store/pages/home-store/home-store.module').then((m) => m.HomeStoreModule),
    // canActivate: [RolesGuard],
    // data: {roles: [ROLE_LISTA_USUARIO]},
  },
  {
    path: 'user-wallet',
    loadChildren: () => import('./pages/broker/pages/user-wallet/user-wallet.module').then((m) => m.UserWalletModule),
    canActivate: [AuthGuard],
    // data: {roles: [ROLE_LISTA_USUARIO]},
  },
];

const routes: Routes = [
  {
    path: '',
    component: AppTemplateComponent,
    children: appTemplateRoutes,
    // canActivate: [AuthGuard],
  },
  {
    path: '',
    component: LoginTemplateComponent,
    children: loginTemplateRoutes,
    canActivate: [NotLoggedInGuard],
  },
  {
    path: '',
    component: HomeTemplateComponent,
    children: HomeTemplateRoutes,
    // canActivate: [NotLoggedInGuard],
  },
  {
    path: '',
    component: LoginTemplateComponent,
    children: errorRoutes,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
