import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { ProductPromotion } from '../../model/product-promotion';
import { BaseWS } from './base.ws';
import { ProductType } from '../../model/product-type';

@Injectable()
export class ProductPromotionWS extends BaseWS<ProductPromotion> {

  constructor(
    protected override httpClient: HttpClient,
    protected override env: EnvironmentService
  ) {
    super('product', '/promotion', httpClient, env);
  }

  findSequence(
    coinId: number,
    type: ProductType
  ): Observable<ProductPromotion[]> {
    const options = {
      params: {
        coinId,
        type
      },
    };
    return this.httpClient.get<ProductPromotion[]>(`${this.resourceRequestPath()}/sequence/`, options);
  }

  findByProductId(productId: number): Observable<ProductPromotion[]> {
    return this.httpClient.get<ProductPromotion[]>(`${this.resourceRequestPath()}/product/${productId}/`)
  }
}
