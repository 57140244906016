import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseWS } from './base.ws';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '../environment.service';
import { FavoriteArtist } from '../../model/favorite-artist';

@Injectable()
export class FavoriteArtistWS extends BaseWS<FavoriteArtist> {

  constructor(
    protected override httpClient: HttpClient,
    protected override env: EnvironmentService
  ) {
    super('artist', '/favorite', httpClient, env);
  }

  findByUserId(): Observable<FavoriteArtist[]> {
    return this.httpClient.get<FavoriteArtist[]>(`${this.resourceRequestPath()}/user/`);
  }

  findByUserIdAndArtistId(artistId: number): Observable<FavoriteArtist> {
    return this.httpClient.get<FavoriteArtist>(`${this.resourceRequestPath()}/artist/${artistId}/user/`);
  }

  deleteByUserAndArtistId(artistId: number): Observable<number> {
    return this.httpClient.delete<number>(`${this.resourceRequestPath()}/${artistId}/user/`);
  }

}
