<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: transparent; display: block; shape-rendering: auto;" width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
<g transform="rotate(0 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.99s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(3.6 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.98s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(7.2 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.97s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(10.8 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.96s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(14.4 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.95s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(18 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.94s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(21.6 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.93s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(25.2 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.92s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(28.8 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.91s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(32.4 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(36 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.89s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(39.6 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.88s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(43.2 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.87s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(46.8 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.86s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(50.4 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.85s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(54 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.84s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(57.6 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.83s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(61.2 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.82s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(64.8 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.81s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(68.4 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(72 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.79s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(75.6 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.78s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(79.2 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.77s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(82.8 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.76s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(86.4 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(90 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.74s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(93.6 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.73s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(97.2 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.72s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(100.8 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.71s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(104.4 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.7s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(108 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.69s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(111.6 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.68s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(115.2 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.67s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(118.8 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.66s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(122.4 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.65s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(126 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.64s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(129.6 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.63s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(133.2 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.62s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(136.8 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.61s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(140.4 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(144 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.59s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(147.6 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.58s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(151.2 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.57s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(154.8 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.56s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(158.4 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.55s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(162 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.54s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(165.6 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.53s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(169.2 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.52s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(172.8 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.51s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(176.4 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(180 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.49s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(183.6 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.48s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(187.2 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.47s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(190.8 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.46s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(194.4 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.45s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(198 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.44s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(201.6 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.43s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(205.2 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.42s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(208.8 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.41s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(212.4 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(216 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.39s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(219.6 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.38s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(223.2 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.37s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(226.8 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.36s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(230.4 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.35s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(234 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.34s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(237.6 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.33s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(241.2 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.32s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(244.8 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.31s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(248.4 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(252 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.29s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(255.6 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.28s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(259.2 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.27s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(262.8 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.26s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(266.4 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(270 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.24s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(273.6 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.23s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(277.2 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.22s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(280.8 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.21s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(284.4 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.2s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(288 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.19s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(291.6 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.18s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(295.2 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.17s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(298.8 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(302.4 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.15s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(306 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.14s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(309.6 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.13s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(313.2 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.12s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(316.8 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.11s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(320.4 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.1s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(324 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.09s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(327.6 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(331.2 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.07s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(334.8 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.06s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(338.4 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.05s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(342 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.04s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(345.6 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.03s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(349.2 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.02s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(352.8 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.01s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(356.4 50 50)">
  <rect x="49.5" y="41.5" rx="0" ry="0" width="1" height="1" fill="#618264">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
  </rect>
</g>
<!-- [ldio] generated by https://loading.io/ --></svg>
