export const environment = {
  hostURL: 'https://api.orkabrasil.com.br',
  cognito: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_7TBKwCP3N',
    userPoolWebClientId: '3cj77p9os9sfqje6uga044lcfs',
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
  production: false,
};
