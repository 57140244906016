import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { Artist } from 'src/app/shared/model/artist';
import { Coin } from 'src/app/shared/model/coin';
import { ProductPromotion } from 'src/app/shared/model/product-promotion';
import { convertPhotosOfList } from 'src/app/shared/utils/photo-utils';
import { ArtistWS } from '../../../shared/services/ws/artist.ws';
import { CoinWS } from '../../../shared/services/ws/coin.ws';
import { ProductPromotionWS } from '../../../shared/services/ws/product-promotion.ws';
import { GlobalVarsService } from 'src/app/shared/services/global-vars.service';
import { ConfigurationWS } from 'src/app/shared/services/ws/configuration.ws';
import { checkMaintenance } from 'src/app/shared/utils/check-manutenence';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {
  items: MenuItem[];
  moedaDestaqueList = [];
  saleProductList = [];
  promotionProductCardType: string = 'tertiary';

  artists: Artist[] = [];
  productPromotions: ProductPromotion[] = [];
  coins: Coin[] = [];
  globalSelectedCoinId: number;

  isLoggedIn = true;

  loading: boolean = false;

  constructor(
    private router: Router,
    private artistWS: ArtistWS,
    private productPromotionWS: ProductPromotionWS,
    private coinWS: CoinWS,
    private globalVars: GlobalVarsService,
    private configurationWS: ConfigurationWS,
  ) {}

  @Input() itens: any;
  itemsCarrousel = [
    {
      imageUrl: './assets/images/home/00.png',
      link: '/experience/home',
    },
    /*{
      imageUrl: './assets/images/home/02.png',
      link: '',
    },*/
    {
      imageUrl: './assets/images/home/00.png',
      link: '',
    },
  ];


  ngOnInit(): void {
    checkMaintenance(this.router, this.configurationWS)
    this.isLogged();
    this.searchData();
    this.globalSelectedCoinId = this.globalVars.getCoinSelected();
  }

  isLogged(): void {
    this.isLoggedIn = this.globalVars.isAuthenticated();

    if(!this.isLoggedIn) {
      this.router.navigate(['/login']);
    }
  }

  searchData() {
    this.loading = true
    forkJoin([
      this.artistWS.findSequence(),
      this.productPromotionWS.findSequence(this.globalSelectedCoinId, 'DIGITAL'),
      this.coinWS.findTrend()
    ]).subscribe(
      (result) => {
        this.loading = false
        this.artists = result[0];
        convertPhotosOfList(this.artists, '../../assets/images/avatar.jpeg');
        this.productPromotions = result[1];
        convertPhotosOfList(this.productPromotions, '../../../../assets/images/product-default.png');
        this.coins = result[2];
        this.coins ? this.coins.sort(this.higherQuoteFirst) : this.coins;
        convertPhotosOfList(this.coins, '../../assets/images/avatar.jpeg');
      },
    );
  }

  higherQuoteFirst(coinA, coinB) {
    let trendCoinA: number = parseFloat(
      ((parseFloat(coinA.lastQuote) * 100) / parseFloat(coinA.currentQuote) - 100).toFixed(2),
    );
    let trendCoinB: number = parseFloat(
      ((parseFloat(coinB.lastQuote) * 100) / parseFloat(coinB.currentQuote) - 100).toFixed(2),
    );

    if (trendCoinA > trendCoinB) {
      return -1;
    }
    if (trendCoinB > trendCoinA) {
      return 1;
    }
    return 0;
  }
}
function checkMaintenence() {
  throw new Error('Function not implemented.');
}

