import { Injectable, NgZone } from '@angular/core';
import { Message, MessageService as PrimengMessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private readonly DEFAULT_MESSAGE = {life: 5000, closable: true};

  constructor(
    private zone: NgZone,
    private primengMessageService: PrimengMessageService,
  ) {
  }

  public showInfo(title: string, message: string): void {
    this.primengMessageAdd({severity: 'info', summary: title, detail: message});
  }

  public showSuccess(message: string): void {
    this.primengMessageAdd({severity: 'success', summary: 'Sucesso', detail: message});
  }

  public showError(message: string): void {
    if (message != undefined && message.trim() != '') {
    this.primengMessageAdd({severity: 'error', summary: 'Erro', detail: message});
    }
    else {
      console.trace("erro sem mensagem definida")
    }
  }

  public showWarning(message: string): void {
    this.primengMessageAdd({severity: 'warn', summary: 'Aviso', detail: message});
  }

  public showAcessoNegado(message?: string): void {
    const msg = 'Usuário sem permissão para acessar esta página. ' + message;
    this.primengMessageAdd({severity: 'error', summary: 'Acesso Negado!', detail: msg});
  }

  public showInvalidAccess(): void {
    const msg = 'Para garantir a segurança dos dados, é necessário realizar seu acesso novamente. ';
    this.primengMessageAdd({severity: 'error', summary: 'Acesso expirado!', detail: msg});
  }

  public showToast(severity: string, title: string, message: string): void {
    this.primengMessageAdd({severity, summary: title, detail: message});
  }

  private primengMessageAdd(pNgMessage: Message): void {
    // Roda assim pois ha momentos em que o angular nao atualiza o estado da tela e nao mostra o toast na hora certa.
    this.zone.run(() => {
      this.primengMessageService.add(this.getPrimeNgMessage(this.getPrimeNgMessage(pNgMessage)));
    });
  }

  private getPrimeNgMessage(pNgMessage: Message): Message {
    return Object.assign(this.DEFAULT_MESSAGE, pNgMessage);
  }

}
