import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FlexModule} from "@angular/flex-layout";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {HeaderComponent} from "./header.component";
import {SelectButtonModule} from "primeng/selectbutton";
import {SidebarModule} from "primeng/sidebar";
import {BadgeModule} from "primeng/badge";
import {MenuModule} from "../menu/menu.module";
import { UserNotificationWS } from 'src/app/shared/services/ws/user-notification.ws';

const routes: Routes = [
  {path: '', component: HeaderComponent}
];

@NgModule({
  declarations: [
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FlexModule,
    ReactiveFormsModule,
    FormsModule,

    //**PrimeNG**//
    SidebarModule,
    BadgeModule,
    MenuModule,
    SelectButtonModule,
  ],
  exports: [
    HeaderComponent
  ],
  providers: [UserNotificationWS]
})
export class HeaderModule {
}
