<!-- <app-chat-button *ngIf="!loading" [route]="'/chat'"> </app-chat-button> -->
<div *ngIf="loading" class="main-content">
  <div class="home-template" fxLayout="column">
    <div fxLayout="column" fxFlexAlign="center" class="home-content">
      <ngx-skeleton-loader
        count="1"
        [theme]="{
        'margin-top':'74px',
        'height': '214px',
        'width': '390px',
        'border-radius': '5px',
        border: '1px solid white'
      }"
      ></ngx-skeleton-loader>
    </div>

    <div fxFlex>
      <div fxLayout="column" class="carteira">
        <div fxFlex fxLayoutAlign="center">
          <ngx-skeleton-loader
            count="1"
            style="width: 110px; margin-right: 220px; margin-top: 43px"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>

    <div class="d-button-artist" fxLayout="column">
      <div fxFlex fxLayout="row">
        <div fxLayout="column" fxLayoutGap="10px">
          <div fxFlex fxLayoutAlign="center">
            <ngx-skeleton-loader
              count="4"
              appearance="circle"
              [theme]="{
              'height': '85px',
              'width': '85px',
              border: '1px solid white'}"
            ></ngx-skeleton-loader>
          </div>
          <div fxFlex fxLayoutAlign="center" fxLayoutGap="10px">
            <ngx-skeleton-loader
              count="4"
              appearance="line"
              [theme]="{
              'height': '25px',
              'width': '85px',
              border: '1px solid white'}"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>

    <div fxFlex style="margin-top: '86px'">
      <div fxFlex fxLayout="row">
        <div fxFlex fxLayoutAlign="center">
          <ngx-skeleton-loader
            count="1"
            style="width: 175px; margin-right: 150px; margin-top: 17px; margin-bottom: 10px"
            [theme]="{'height': '30px'}"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>

    <div fxLayout="column" fxFlexAlign="center" class="home-content">
      <ngx-skeleton-loader
        count="1"
        [theme]="{
        'height': '250px',
        'width': '350px',
        'border-radius': '55px',
        border: '1px solid white'
      }"
      ></ngx-skeleton-loader>
    </div>
  </div>
</div>

<div *ngIf="!loading" class="main-content">
  <div class="margin-home">
    <app-carousel-component [items]="itemsCarrousel" [autoplay]="true" [showGradient]="true" [slide]="true">
    </app-carousel-component>
  </div>
  <div fxLayout="column" fxLayoutGap="20">
    <div class="home-template">
      <div fxLayout="column" fxFlexAlign="center" class="home-content">
        <div fxFlex class="artists">
          <div class="d-button-artist" fxLayout="column">
            <div fxFlex class="home-subtitle">Projetos</div>
            <div fxFlex>
              <app-artist-circle-list [items]="artists"></app-artist-circle-list>
            </div>
            <!-- <div fxFlex fxFlexAlign="center">
              <button
                pButton
                pRipple
                label="Ver todos"
                icon="pi pi-chevron-right"
                class="p-button-rounded ver-todas-button"
                iconPos="right"
                [routerLink]="['/broker/artist-list']"
              ></button>
            </div> -->
          </div>
        </div>

        <!-- <div fxFlex class="institutional-video">
          <div fxLayout="column">
            <div fxFlex>
              <div class="home-subtitle">Como Funciona</div>
            </div>

            <div fxFlex class="padding-22-left-right">
              <iframe
                style="height: 100%; width: 100%; aspect-ratio: 9 / 16"
                src="https://www.youtube.com/embed/p2X2QlRmC_A"
                frameborder="0"
                class="video-border"
              ></iframe>
            </div>
          </div>
        </div> -->

        <div fxFlex class="oQuePossoFazerComAsMoedas">
          <div fxLayout="column" class="coinAll">
            <div fxFlex>
              <div fxLayout="column">
                <div fxFlex>
                  <div class="home-subtitle">
                    O que posso fazer com os 
                      <br />
                    Créditos de Carbono?
                  </div>
                </div>
              </div>
            </div>

            <div fxFlex fxFlexAlign="center">
              <div fxLayout="row" class="moedasHome">
                <!-- <div fxFlex fxFlexAlign="center">
                  <div class="coinImgColumnOne" fxLayout="column" fxLayoutGap="10">
                    <div fxFlex>
                      <img class="one" src="assets/images/coinHome/Moeda.png" />
                    </div>
                    <div fxFlex>
                      <img class="two" src="assets/images/coinHome/Moeda.png" />
                    </div>
                    <div fxFlex>
                      <img class="tree" src="assets/images/coinHome/Moeda.png" />
                    </div>
                    <div fxFlex>
                      <img class="four" src="assets/images/coinHome/Moeda.png" />
                    </div>
                  </div>
                </div> -->

                <div fxFlex fxFlexAlign="center">
                  <div fxLayout="column" class="textHowWork">
                    <div class="box">
                      <span>1</span>
                      <label>Compensar sua pegada de carbono e contribuir para um planeta mais sustentável
                      </label>
                    </div>
                    <div class="box">
                      <span>2</span>
                      <label>Trocar por produtos sustentáveis e ecologicamente corretos em nossa loja
                      </label>
                    </div>
                    <div class="box">
                      <span>3</span>
                      <label>Aproveitar descontos e promoções exclusivas em parcerias ambientais</label>
                    </div>
                    <div class="box">
                      <span>4</span>
                      <label>Ganhar certificações e relatórios de impacto ambiental
                      </label>
                    </div>
                    <div class="box">
                      <span>5</span>
                      <label>Participar de eventos e fóruns exclusivos sobre sustentabilidade</label>
                    </div>
                    <div class="box">
                      <span>6</span>
                      <label>Apoiar diretamente projetos de compensação ambiental e preservação</label>
                    </div>
                  </div>
                </div>

                <!-- <div fxFlex fxFlexAlign="center">
                  <div class="coinImgColumnTwo" fxLayout="column" fxLayoutGap="20">
                    <div fxFlex fxFlexAlign="end">
                      <img class="five" src="assets/images/coinHome/Moeda.png" />
                    </div>
                    <div fxFlex fxFlexAlign="end">
                      <img class="six" src="assets/images/coinHome/Moeda.png" />
                    </div>
                    <div fxFlex fxFlexAlign="end">
                      <img class="seven" src="assets/images/coinHome/Moeda.png" />
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="productPromotions.length > 0" fxFlex class="promocoes">
          <div class="home-subtitle">Promoções</div>
          <div fxLayout="column" class="product-home">
            <app-product-card-list [items]="productPromotions" [type]="promotionProductCardType || 'primary'">
            </app-product-card-list>
          </div>
        </div>

        <div fxFlex class="MoedaEmDestaques">
          <div class="home-subtitle">Projetos em destaque</div>

          <div class="padding-22-left-right">
            <app-coin-quote-list [items]="coins" [goTo]="'/broker/coin-store'" [backTo]="'/home'">
            </app-coin-quote-list>
          </div>

          <div class="d-button-artist" fxLayout="column">
            <div fxFlex fxFlexAlign="center center" class="button-footer-artist pointer">
              <button
                pButton
                pRipple
                label="Ver todas"
                icon="pi pi-chevron-right"
                class="p-button-rounded ver-todas-button"
                iconPos="right"
                [routerLink]="['/broker/artist-list']"
              ></button>
            </div>
          </div>
        </div>

        <div fxFlex class="PerguntasFrequentes">
          <div class="home-subtitle">
            Perguntas<br />
            Frequentes
          </div>
          <div fxLayout="column">
            <div fxFlex>
              <div class="padding-22-left-right">
                <p-accordion>
                  <p-accordionTab header="O QUE É ORKA?" [selected]="true">
                    <p class="question-text">
                      Orka é uma corretora de criptoativos com foco em créditos de carbono. Ou seja, é uma plataforma digital que permite que indivíduos e empresas adquiram tokens que representam créditos de carbono certificados. Esses tokens podem ser usados para compensar a pegada de carbono, apoiar projetos sustentáveis e contribuir diretamente para a preservação do meio ambiente. Além de reduzir o impacto ambiental, o seu investimento pode valorizar ao longo do tempo, e você ainda tem a oportunidade de aproveitar produtos e serviços sustentáveis em nossa loja, além de acessar vantagens e promoções exclusivas.
                    </p>
                  </p-accordionTab>
                </p-accordion>

                <p-accordion>
                  <p-accordionTab header="A PLATAFORMA ORKA É SEGURA?" [selected]="false">
                    <p class="question-text">
                      A Orka investe em tecnologia e infraestrutura por meio de parceiros e empresas de tecnologia líderes globais em testes de segurança, tudo para oferecer o que há de melhor dentro da nova economia digital. De igual modo, a Orka segue rigorosas normas de segurança da informação e avaliação periódica de seus processos para garantir total conformidade com regulamentações nacionais e internacionais.
                    </p>
                  </p-accordionTab>
                </p-accordion>

                <p-accordion>
                  <p-accordionTab header="COMO COMPRAR TOKENS DE CRÉDITO DE CARBONO NA ORKA?" [selected]="false">
                    <p class="question-text">
                      Na plataforma Orka, você pode comprar tokens de crédito de carbono de maneira simples e segura. Primeiro, você precisa ter uma conta na Orka. Em seguida, realize um depósito em sua conta Orka, que pode ser feito via Pix, TED ou DOC. Pesquise o token de crédito de carbono desejado e clique em "comprar". Escolha o quanto deseja investir e pronto.
                    </p>
                  </p-accordionTab>
                </p-accordion>

                <p-accordion>
                  <p-accordionTab
                    header="PARA COMPRAR TOKENS PRECISO TRANSFERIR REAIS (R$) DE UMA CONTA DO MEU CPF?"
                    [selected]="false"
                  >
                    <p class="question-text">
                      Sim! A quantia em reais que você depositará em nossa plataforma precisa ter origem de uma conta bancária da mesma titularidade da conta cadastrada na plataforma Orka, ou seja, com o mesmo CPF ou CNPJ.
                    </p>
                  </p-accordionTab>
                </p-accordion>

                <p-accordion>
                  <p-accordionTab header="COMO CRIAR UMA CONTA ORKA?" [selected]="false">
                    <p class="question-text">
                      Criar uma conta na Orka é fácil e gratuito. Na página inicial do site da Orka, clique em "Criar Conta". Em seguida, defina seu e-mail de cadastro, telefone celular e senha. É muito importante que você crie uma senha forte para aumentar a segurança da sua conta. Continue preenchendo com as informações solicitadas. Depois de completar o formulário, acesse o e-mail que você usou no cadastro para ativar sua conta. Caso não esteja na caixa de entrada, dê uma olhada na caixa de spam. Lembre-se de que não é possível associar mais de uma conta ao mesmo e-mail, CPF ou CNPJ.
                    </p>
                  </p-accordionTab>
                </p-accordion>
              </div>
            </div>

            <!-- <div fxFlex fxFlexAlign="center">
              <button
                pButton
                pRipple
                label="Ver todas"
                icon="pi pi-chevron-right"
                class="p-button-rounded ver-todas-button"
                iconPos="right"
                [routerLink]="['/faq']"
              ></button>
            </div> -->
          </div>
        </div>
      </div>

      <div class="sticky" *ngIf="!isLoggedIn">
        <app-footer-landing-page></app-footer-landing-page>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
