import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { DividerModule } from 'primeng/divider';
import { ArtistCardModule } from '../artist/artist-card.module';
import { CoinQuotePage } from './coin-quote.page';
import { FavoriteHeaderArtistModule } from '../favorite-header-artist/favorite-header-artist.module';

@NgModule({
  declarations: [CoinQuotePage],
  imports: [
    CommonModule,
    RouterModule,
    FlexModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule.forRoot(),

    //**Components**//
    ArtistCardModule,
    FavoriteHeaderArtistModule,

    //**PrimeNG**//
    DividerModule,
  ],
  exports: [CoinQuotePage],
  providers: [],
})
export class CoinQuoteModule {}
