import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from '../../model/setting';
import { EnvironmentService } from '../environment.service';
import { BaseWS } from './base.ws';

export interface StatusProps {
  failing: boolean;
  message: string;
}
@Injectable()
export class ConfigurationWS extends BaseWS<Configuration> {
  constructor(protected override httpClient: HttpClient, protected override env: EnvironmentService) {
    super('configuration', '/configuration', httpClient, env);
  }

  findByName(name: string): Observable<Configuration> {
    return this.httpClient.get<Configuration>(`${this.resourceRequestPath()}/name/${name}/`);
  }

  findStatus(): Observable<StatusProps> {
    return this.httpClient.get<StatusProps>(`${this.resourceRequestPath()}/status/`);
  }
}
