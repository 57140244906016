<ngx-spinner bdColor="#00000080" color="#ffffff"
             type="ball-clip-rotate" size="large" [fullScreen]="true">
  <br><br>
  <p style="color: #ffffff; margin-top: 50px; font-size: 20px;">Loading...</p>
</ngx-spinner>

<p-toast [style]="{right: '15px', width: '92vw', maxWidth: '400px'}"></p-toast>

<p-confirmDialog [style]="{width: '92vw', maxWidth: '500px'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>

<router-outlet></router-outlet>
