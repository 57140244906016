<div *ngIf="goTo===''" class="coin-quote">
  <div class="coin-quote-top">
    <div class="coin-quote-left">
      <app-artist-card
        [imageUrl]="item?.photo[0]?.fileUrl"
        [name]="item.artistName || item.coinName || item.name"
        [coin]="item.acronym"
        [type]="artistType || 'primary'"
      ></app-artist-card>
    </div>
    <div *ngIf="type==='primary'" class="coin-quote-right">
      <app-favorite-header-artist
        [artistId]="item?.artistId ?? item?.id"
        *ngIf="item?.artistId || item?.id"
      ></app-favorite-header-artist>
      <div class="coin-quote-profile-money">
        <span>{{ item?.currentQuote ? item?.currentQuote : 1  | currency:'BRL'}}</span>
      </div>
      <div class="coin-quote-tag" [ngClass]="getColorTag(trend)">
        <span class="coin-quote-tag-value"
          ><i class="pi" [ngClass]="getIconTag(trend)"></i>{{(trend ? trend : 0) + "%"}}</span
        >
      </div>
    </div>
    <div *ngIf="type==='secondary'" class="coin-quote-right">
      <app-favorite-header-artist
        [artistId]="item?.artistId ?? item?.id"
        *ngIf="item?.artistId || item?.id"
      ></app-favorite-header-artist>
      <div class="coin-quote-tag secondary" [ngClass]="getColorTag(trend)">
        <span class="coin-quote-tag-value"
          ><i class="pi" [ngClass]="getIconTag(trend)"></i>{{(trend ? trend : 0) + "%"}}</span
        >
      </div>
    </div>
  </div>
  <div *ngIf="type==='primary'" class="coin-quote-footer">Últimas 24hs</div>
  <p-divider *ngIf="type==='secondary'" class="divisor"></p-divider>
  <div *ngIf="type==='secondary'" class="coin-quote-footer-secondary">
    <div class="coin-quote-footer-secondary-left">
      <span class="time">24h</span>
    </div>
    <div class="coin-quote-footer-secondary-right">
      <span class="coin-quote-footer-secondary-right-title">Cotação</span>
      <span class="coin-quote-footer-secondary-right-value">R${{ item?.currentQuote ? item?.currentQuote : 1 }}</span>
    </div>
  </div>
</div>

<div *ngIf="goTo!==''" class="coin-quote pointer">
  <div class="coin-quote-top">
    <div
      class="coin-quote-left"
      [routerLink]="[goTo]"
      [state]="{ data: {acronym: item.acronym, artistName: item.artistName, photo: item.photo, currentQuote: item.currentQuote, coinId: item.id, backTo: backTo} }"
    >
      <app-artist-card
        [imageUrl]="item?.photo[0]?.fileUrl"
        [name]="item.artistName || item.coinName || item.name"
        [coin]="item.acronym"
        [type]="artistType || 'primary'"
      ></app-artist-card>
    </div>
    <div *ngIf="type==='primary'" class="coin-quote-right">
      <app-favorite-header-artist
        [artistId]="item?.artistId ?? item?.id"
        *ngIf="item?.artistId || item?.id"
      ></app-favorite-header-artist>
      <div class="coin-quote-profile-money">
        <span>{{ item?.currentQuote | currency:'BRL'}}</span>
      </div>
      <div class="coin-quote-tag" [ngClass]="getColorTag(trend)">
        <span class="coin-quote-tag-value"
          ><i class="pi" [ngClass]="getIconTag(trend)"></i>{{(resultTrend ? resultTrend : 0) + "%"}}</span
        >
      </div>
    </div>
    <div *ngIf="type==='secondary'" class="coin-quote-right">
      <app-favorite-header-artist
        [artistId]="item?.artistId ?? item?.id"
        *ngIf="item?.artistId || item?.id"
      ></app-favorite-header-artist>
      <div class="coin-quote-tag secondary" [ngClass]="getColorTag(trend)">
        <span class="coin-quote-tag-value"
          ><i class="pi" [ngClass]="getIconTag(trend)"></i>{{(trend ? trend : 0) + "%"}}</span
        >
      </div>
    </div>
  </div>
  <div *ngIf="type==='primary'" class="coin-quote-footer">Últimas 24hs</div>
  <p-divider *ngIf="type==='secondary'" class="divisor"></p-divider>
  <div *ngIf="type==='secondary'" class="coin-quote-footer-secondary">
    <div class="coin-quote-footer-secondary-left">
      <span class="time">24h</span>
    </div>
    <div class="coin-quote-footer-secondary-right">
      <span class="coin-quote-footer-secondary-right-title">Cotação</span>
      <span class="coin-quote-footer-secondary-right-value">R${{ item?.currentQuote ? item?.currentQuote : 1 }}</span>
    </div>
  </div>
</div>
