<div class="sideMenu padding-22-left-right" fxLayout="column">
  <div fxFlex *ngIf="isLoggedIn">
    <div class="notification" fxLayout="row" [routerLink]="['/profile/notification']">
      <div fxFlex="10">
        <img alt="Card" src="../../../assets/images/icon-sideMenu/notification.svg" style="width: 30px; height: 30px" />
      </div>
      <div fxFlex>
        <button pButton pBadge label="Notificações" class="p-button-text"></button>
      </div>
      <div fxFlex *ngIf="+notification > 0">
        <p-badge [value]="notification" styleClass="mr-2" class="badge"></p-badge>
      </div>
    </div>
  </div>

  <div fxLayout="row" [routerLink]="['/home']" class="home-button">
    <div class="home-icon">
      <img src="assets/images/icon-sideMenu/Home.svg" />
    </div>
    <label fxLayoutAlign="start center" class="title-home">Início</label>
  </div>

  <div fxFlex *ngIf="isLoggedIn">
    <div [routerLink]="['/profile/user-profile']" class="perfil">
      <div class="perfilElipse">
        <span>K</span>
      </div>
      <label>Perfil</label>
    </div>
  </div>

  <div fxFlex *ngIf="isLoggedIn">
    <div [routerLink]="['/experience/home']" class="perfil">
      <div class="exp-icon">
        <img class="ticketIcon" src="assets/images/ticket.svg" alt="ticket-icon" />
      </div>
      <label>Prêmios e Cupons</label>
    </div>
  </div>

  <div fxFlex>
    <div class="linksMenu">
      <div *ngFor="let item of menu">
        <div fxLayout="row" class="sideMenuLinks">
          <div fxFlex="10">
            <img [src]="'assets/images/icon-sideMenu/' + item.icon" alt="inicio" />
          </div>

          <div fxFlex="80" class="itemName">
            <label
              ><a [routerLink]="item.routerLink"
                ><b>{{ item.name }}</b></a
              ></label
            >
          </div>

          <!--          <div fxFlex class="menu-item">-->
          <!--            <label><a [routerLink]="item.routerLink">-->
          <!--              <b>&nbsp;&nbsp;&nbsp;{{ item.label }}</b></a></label>-->
          <!--          </div>-->
        </div>

        <div class="exit-account">
          <ol *ngFor="let itemMenu of item.items">
            <a [routerLink]="itemMenu.routerLink">
              {{ itemMenu.label }}
            </a>
          </ol>
        </div>
      </div>

      <div fxLayout="row" *ngIf="isLoggedIn" class="sideMenuHelp">
        <div [routerLink]="['/chat']" class="help-icon">
          <div class="exp-icon">
            <img class="ticketIcon" src="assets/images/icon-sideMenu/help-icon.svg" alt="ticket-icon" />
          </div>
          <label>Precisa de Ajuda?</label>
        </div>
      </div>

      <div fxLayout="row" class="sideMenuLinks lastItem" *ngIf="isLoggedIn">
        <div fxFlex="10">
          <img src="assets/images/icon-sideMenu/signOut.svg" alt="inicio" />
        </div>

        <div fxFlex="80" class="itemName">
          <label
            ><a (click)="signOut()"><b>Sair da Conta</b></a></label
          >
        </div>
      </div>
    </div>
  </div>
</div>
