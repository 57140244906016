import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, filter, forkJoin } from 'rxjs';
import { GlobalVarsService } from 'src/app/shared/services/global-vars.service';
import { UserNotificationWS } from './../../shared/services/ws/user-notification.ws';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  sideMenu: any;

  stateOptions1: any[];
  stateOptions2: any[];
  stateOptions3: any[];
  value1: string = '';
  value2: any;

  notifications = '';

  subscription: Subscription;

  constructor(
    private router: Router,
    private userNotificationWS: UserNotificationWS,
    private globalVars: GlobalVarsService,
  ) {
    this.stateOptions1 = [{ label: 'Loja', value: '/home-store' }];
    this.stateOptions2 = [{ label: 'Tokens', value: '/user-wallet' }];
    this.stateOptions3 = [{ label: '', value: 'menu', icon: 'pi pi-bars' }];
  }
  ngOnInit(): void {
    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // Verifica a rota completa
        const currentRoute = event.urlAfterRedirects;

        // Define o botão ativo com base na rota
        if (currentRoute === '/user-wallet') {
          this.value1 = '/user-wallet'; // Ativa o botão de Tokens
        } else if (currentRoute === '/home-store') {
          this.value1 = '/home-store'; // Ativa o botão da Loja
        } else {
          this.value1 = ''; // Nenhuma rota correspondente
        }
      });

    this.getNotificationNumber();
  }

  isLoggedIn() {
    return this.globalVars.isLoggedIn;
  }

  RemoveSelection() {
    this.value1 = '';
  }

  getNotificationNumber() {
    if (this.isLoggedIn()) {
      forkJoin([this.userNotificationWS.countByUser()]).subscribe((result) => {
        this.notifications = result[0].toString();
      });
    }
  }

  setSelectionOnReturn(): void {
    if (this.value1 == 'loja') {
      this.value1 = 'tokens';
    }

    if (this.value1 == 'tokens') {
      this.value1 = 'loja';
    }
  }
}
