<div class="carousel">
  <div *ngIf="items">
    <owl-carousel-o *ngIf="slide" [options]="customOptions">
      <ng-container *ngFor="let item of items; let i=index">
        <ng-template carouselSlide [id]="'slide-'+i">
          <div *ngIf="showGradient" class="gradient"></div>
          <img [src]="item.imageUrl" [routerLink]="['/experience/home']" alt="" />
          <div *ngIf="item.link" class="link" [routerLink]="[item.link]"></div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>

    <div
      *ngIf="!slide"
      class="mySlides slideshow-container carousel-container"
      (touchstart)="onTouchStart($event)"
      (touchend)="onTouchEnd($event)"
    >
      <img
        *ngFor="let item of items; let i=index"
        [src]="item.imageUrl"
        [ngClass]="{'image-active': selectedindex === i}"
        alt=""
        (click)="openSlides(i)"
      />
      <div *ngIf="showGradient" class="gradient"></div>
      <div [innerHTML]="items[selectedindex]?.message" class="message"></div>
      <div class="carousel-dots">
        <span
          *ngFor="let item of items; let i=index"
          class="dot"
          (click)="selectImage(i)"
          [ngClass]="{'active': selectedindex == i}"
        >
        </span>
      </div>

      <a *ngIf="showNavigation" class="prev" (click)="getPrevSlide()">&#10094;</a>
      <a *ngIf="showNavigation" class="next" (click)="getNextSlide()">&#10095;</a>
    </div>

    <div *ngIf="showMiniature && !slide" class="carousel-mini">
      <div class="carousel-mini-grid">
        <div
          *ngFor="let item of items; let i=index"
          class="carousel-mini-item"
          [ngClass]="{'carousel-mini-item-active': selectedindex === i}"
        >
          <label>
            <img [src]="item?.imageUrl" (click)="selectImage(i)" alt="" />
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
