<div *ngIf="type==='primary'">
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutGap="25px"
    fxLayoutAlign="center center"
    style="height: 100%; width: 100%"
  >
    <div *ngFor="let item of items" fxFlex="1 1" fxLayoutAlign="center center" style="height: 100%; width: 100%">
      <app-product-card
        [item]="item"
        [showButtonDetails]="showButtonDetails"
        [showShoppingCartDetails]="showShoppingCartDetails"
        [productQuantity]="item.userProductQuantity"
        [type]="type || 'primary'"
        (changeProductQuantity)="changeProductQuantity($event)"
        (removeProduct)="removeItem($event)"
        [currentCurrency]="currentCurrency"
        [currentQuote]="currentQuote"
        style="width: 100%; margin: 0 29px"
      >
      </app-product-card>
    </div>
  </div>
</div>

<div *ngIf="type==='secondary'">
  <ul class="sale-ul" fxLayout="row">
    <li *ngFor="let item of items">
      <app-product-card
        [item]="item"
        [showButtonDetails]="showButtonDetails"
        [type]="type || 'primary'"
        [currentCurrency]="currentCurrency"
        [currentQuote]="currentQuote"
      >
      </app-product-card>
    </li>
  </ul>
</div>

<div *ngIf="type==='tertiary'">
  <div class="sale-ul-promotion" fxLayout="row">
    <div fxFlex *ngFor="let item of items">
      <app-product-card [item]="item" [showButtonDetails]="showButtonDetails" [type]="type || 'primary'">
      </app-product-card>
    </div>
  </div>
</div>
