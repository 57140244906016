import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { EnvironmentService } from "../environment.service";
import { Coin } from '../../model/coin';
import { BaseWS } from './base.ws';
import {Observable} from 'rxjs';

@Injectable()
export class CoinWS extends BaseWS<Coin> {

  constructor(
    protected override httpClient: HttpClient,
    protected override env: EnvironmentService
  ) {
    super('coin','/coin', httpClient, env);
  }

  findActives(): Observable<Coin[]> {
    return this.httpClient.get<Coin[]>(`${this.resourceRequestPath()}/active/`);
  }

  findTrend(): Observable<Coin[]> {
    return this.httpClient.get<Coin[]>(`${this.resourceRequestPath()}/trend/`);
  }

  findTrendById(id: number): Observable<Coin> {
    return this.httpClient.get<Coin>(`${this.resourceRequestPath()}/trend/${id}/`);
  }

}
