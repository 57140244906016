import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {PrimeNGConfig} from "primeng/api";
import {ptBR} from "./shared/constants/calendar";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  // @ViewChild('confirmDialog') confirmDialog: ConfirmDialogComponent | null = null;

  constructor(
    private config: PrimeNGConfig,
    // public confirmDialogService: ConfirmDialogService,
  ) {
  }

  ngOnInit(): void {
    this.config.setTranslation(ptBR);
  }

  ngAfterViewInit(): void {
    // if (this.confirmDialog != null) {
    //   this.confirmDialogService.confirmDialog = this.confirmDialog;
    // }
  }
}
