import { NgxSpinnerService } from 'ngx-spinner';
import { Component, Input, OnInit } from '@angular/core';
import { FavoriteArtist } from 'src/app/shared/model/favorite-artist';
import { FavoriteArtistWS } from './../../../../shared/services/ws/favorite-artist.ws';
import { GlobalVarsService } from 'src/app/shared/services/global-vars.service';

@Component({
  selector: 'app-favorite-header-artist',
  templateUrl: './favorite-header-artist.component.html',
  styleUrls: ['./favorite-header-artist.component.scss']
})
export class FavoriteHeaderArtistComponent implements OnInit {

  @Input() artistId: number = null;

  favoriteArtist: FavoriteArtist;
  artistType: string;
  isLoading: boolean;

  constructor(
    private favoriteArtistWS: FavoriteArtistWS,
    private spinner: NgxSpinnerService,
    private globalVars: GlobalVarsService
  ) { }

  ngOnInit(): void {
    this.artistType = 'secondary';
    this.isLoggedIn() && this.searchFavoriteArtist();
  }

  isLoggedIn() {
    return this.globalVars.isLoggedIn;
  }

  searchFavoriteArtist() {
    this.isLoading = true;

    if (this.artistId !== null) {
      this.favoriteArtistWS.findByUserIdAndArtistId(this.artistId).subscribe(favoriteArtist => {
        favoriteArtist.id == null
          ? this.favoriteArtist = null
          : this.favoriteArtist = favoriteArtist;

        this.isLoading = false;
      });
    } else {
      this.favoriteArtistWS.findByUserId().subscribe(favoriteArtists => {
        for (var i = 0; i < favoriteArtists.length; i++) {
          if (this.favoriteArtist === favoriteArtists[i]) {
            this.favoriteArtist = favoriteArtists[i];
            break;
          }
        }

        this.isLoading = false;
      });

    }
  }

  favorite() {
    this.isLoading = true;

    if (!this.favoriteArtist) {
      const favoriteArtist: FavoriteArtist = {
        id: null,
        artistId: this.artistId
      }

      this.favoriteArtistWS.save(favoriteArtist).subscribe(favoriteArtistId => {
        this.favoriteArtist = {
          id: favoriteArtistId as number,
          artistId: this.artistId
        }
        this.isLoading = false;
      })
    } else {
      this.favoriteArtistWS.deleteByUserAndArtistId(this.artistId).subscribe(() => {
        this.searchFavoriteArtist();
      })
    }
  }
}
