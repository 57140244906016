import {NgModule} from '@angular/core';
import { CircleSpinnerComponentPage } from './circle-spinner.page';

@NgModule({
  declarations: [
    CircleSpinnerComponentPage,
  ],
  imports: [
  ],
  exports: [
    CircleSpinnerComponentPage
  ],
  providers: []
})
export class CircleSpinnerModule {
}
