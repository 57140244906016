import { Component, OnInit, Input } from '@angular/core';
import { Artist } from 'src/app/shared/model/artist';

@Component({
  selector: 'app-artist-circle-list',
  templateUrl: './artist-circle-list.component.html',
  styleUrls: ['./artist-circle-list.component.scss']
})
export class ArtistCircleListComponent implements OnInit {

  @Input() items: Artist[];

  mockArtist: Artist = {
    id: 1,
    active: false,
    name: 'Em breve',
    coinId: 0,
    photo: [{
      fileUrl: '../../assets/images/home/woman-soon.png'
    }]
  }

  loadArtistList() {
    const rest: number = 4 - this.items.length;

    const newItems: Artist[] = [];

    for (let i = 0; i < rest; i++) {
      newItems.push( this.mockArtist)
    }

    this.items = [...this.items, ...newItems]
  }

  soons: Artist[] = [{
    id: 1,
    active: false,
    name: 'Em breve',
    coinId: 0,
    photo: [{
      fileUrl: '../../assets/images/home/woman-soon.png'
    }]
  }, {
    id: 2,
    active: false,
    name: 'Em breve',
    coinId: 0,
    photo: [{
      fileUrl: '../../assets/images/home/man-soon.png'
    }]
  }, {
    id: 3,
    active: false,
    name: 'Em breve',
    coinId: 0,
    photo: [{
      fileUrl: '../../assets/images/home/woman-soon.png'
    }]
  }]

  constructor() { }

  ngOnInit(): void {
    this.loadArtistList();
  }
}
