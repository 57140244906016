import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from "@angular/common";
import {ArtistCircleComponent} from "./artist-circle.component";

@NgModule({
  declarations: [
    ArtistCircleComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,

    //**PrimeNG**//

  ],
  exports: [
    ArtistCircleComponent
  ],
  providers: []
})
export class ArtistCircleModule {
}
