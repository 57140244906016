import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FlexModule} from "@angular/flex-layout";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {CoinQuoteListPage} from "./coin-quote-list.page";
import {CoinQuoteModule} from "../coin-quote/coin-quote.module";

@NgModule({
  declarations: [
    CoinQuoteListPage,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FlexModule,
    ReactiveFormsModule,
    FormsModule,

    //**Components**//
    CoinQuoteModule,

    //**PrimeNG**//

  ],
  exports: [
    CoinQuoteListPage
  ],
  providers: []
})
export class CoinQuoteListModule {
}
