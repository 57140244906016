import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: 'https://706db1daf2cc45eb9a0be628d9b80160@o4504536939429888.ingest.sentry.io/4504536996184064',
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: ['https://dev.stringsbrasil.com', 'https://app.stringsbrasil.com'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  ignoreErrors: ['Non-Error exception captured'],
  tracesSampleRate: 0.3,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
