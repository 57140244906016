export function convertPhoto(item: any, defaultPhotoPath: string) {
  if (Array.isArray(item.photo)) {
    return;
  } else {
    item.photo && item.photo != "[]" && item.photo != "" ? item.photo = JSON.parse(item.photo) : item.photo = [{ path: defaultPhotoPath, fileUrl: defaultPhotoPath }];
  }
}

export function convertPhotosOfList(items: any[], defaultPhotoPath: string) {
  items.forEach(item => {
    convertPhoto(item, defaultPhotoPath);
  });
}
