import { Component, Input, OnInit } from '@angular/core';
import { GlobalVarsService } from 'src/app/shared/services/global-vars.service';

@Component({
  selector: 'app-chat-button',
  templateUrl: './chat-button.component.html',
  styleUrls: ['./chat-button.component.scss'],
})
export class ChatButtonComponent implements OnInit {
  constructor(private globalVarsService: GlobalVarsService) {}

  @Input() route: string;

  ngOnInit(): void {}
  getQuantityItensCard(): string {
    return (this.globalVarsService.getShoppingCart()?.length || 0).toString();
  }
}
