<div fxLayout="column" class="layout-footer">
  <div fxFlex>
    <div fxLayout="column" fxFlex class="titlePage" fxLayoutGap="5px">
      <img src="../../../assets/images/logo/logo-orka.svg" class="logo-icon" />
      <!-- <img src="../../../assets/images/logo/strings-txt-logo.png" class="logo-text" /> -->
    </div>
  </div>

    <div class="social-links">
      <div >
        <a href="https://www.instagram.com/orkabrasil?igsh=MThrcmc1dTAya3lpbQ==" target="_blank">
          <img alt="insta" class="" src="assets/images/icone-redes-sociais/simple-line-icons_social-instagram.svg" />
        </a>
      </div>
      <!-- <div fxFlex fxFlex.gt-xs="75px" class="text-center">
        <a href="https://www.facebook.com/stringsbrasil" target="_blank">
          <img alt="face" class="pointer padding-25-left-right" src="assets/images/icone-redes-sociais/simple-line-icons_social-facebook.svg" />
        </a>
      </div> -->
      <!-- <div fxFlex fxFlex.gt-xs="75px" class="text-center">
        <a href="https://www.youtube.com/channel/UCvf22iS1OFu8s-o_wJ4NRCA" target="_blank">
          <img
            alt="yt"
            class="pointer padding-25-left-right align-youtube-icon"
            src="assets/images/icone-redes-sociais/simple-line-icons_social-youtube.svg"
          />
        </a>
      </div> -->
      <div class="">
        <a href="https://www.linkedin.com/company/orka-brasil/" target="_blank">
          <img alt="lkn" class="" src="assets/images/icone-redes-sociais/simple-line-icons_social-linkedin.svg" />
        </a>
      </div>
  </div>

  <div class="footer color-white padding-top">
    <div fxFlex>
      <h1 class="attendance">ATENDIMENTO</h1>
    </div>
    <div fxFlex>
      <h1 class="timetable">HORÁRIO: DAS 8H ATÉ 18H</h1>
    </div>
    <div fxFlex>
      <a class="email" href="mailto:atendimento&#64;orkabrasil.com.br?subject=Atendimento Orka"
        >atendimento&#64;orkabrasil.com.br</a
      >
    </div>
    <span class="padding-top">
      <h1 class="copyright">© Copyright 2024 <strong>Orka</strong>. Todos<br />os direitos reservados.</h1>
    </span>
    <div class="term">
      <a href="/profile/terms-and-policies">Termos e Políticas</a>
    </div>
  </div>
</div>
