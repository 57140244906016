import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalVarsService } from 'src/app/shared/services/global-vars.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @Input() notification = '';
  menu: any[] = [];
  userAddress: any = [];

  isLoggedIn = false;

  constructor(private router: Router, private globalVariables: GlobalVarsService) {}

  ngOnInit(): void {
    if (this.globalVariables.isAuthenticated()) {
      this.isLoggedIn = true;
      this.getUserAddress();
    } else {
      this.isLoggedIn = false;
    }
  }

  getUserAddress() {
    this.userAddress = this.globalVariables.getLoggedUser().addresses;
    this.inicializarMenu();
  }

  public inicializarMenu(): void {
    this.menu = [
      {
        name: 'Tokens',
        routerLink: [this.isLoggedIn ? '/user-wallet' : '/login'],
        icon: 'sifrao.svg',
        items: [
          {
            label: 'Comprar e Vender Tokens',
            routerLink: [this.isLoggedIn ? '/broker/coin-store' : '/login'],
          },
          {
            label: 'Minha carteira',
            routerLink: [this.isLoggedIn ? '/broker/property' : '/login'],
          },
          //{
          //  label: 'Depositar',
          //  routerLink: [
          //    !this.isLoggedIn
          //      ? '/login'
          //      : this.userAddress.length === 0
          //      ? '/profile/current-address'
          //      : this.userAddress.length > 0
          //      ? '/broker/deposit-guidance'
          //      : '/user-wallet',
          //  ],
          //},
          {
            label: 'Sacar',
            routerLink: [this.isLoggedIn ? '/broker/confirm-withdraw' : '/login'],
          },
          {
            label: 'Meus Tokens',
            routerLink: [this.isLoggedIn ? '/broker/user-coin-list' : '/login'],
          },
          {
            label: 'Extrato',
            routerLink: [this.isLoggedIn ? '/broker/extract' : '/login'],
          },
          // {
          //   label: 'Extrato de transações',
          //   routerLink: [this.isLoggedIn ? '/broker/transactions' : '/login'],
          // },
        ],
      },
      {
        name: 'Loja',
        icon: 'shopping-cart.svg',
        routerLink: [this.isLoggedIn ? '/home-store' : '/login'],
        items: [
          //{
          //  label: 'Produtos favoritos',
          //  routerLink: [this.isLoggedIn ? '/store/product-list' : '/login'],
          //},
          {
            label: 'Carrinho de compras',
            routerLink: [this.isLoggedIn ? '/store/shopping-cart' : '/login'],
          },
          {
            label: 'Meus pedidos',
            routerLink: [this.isLoggedIn ? '/store/my-orders' : '/login'],
          },
        ],
      },
    ];
  }

  signOut() {
    this.globalVariables.clear();
    this.router.navigate(['/login']);
  }
}
