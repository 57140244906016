import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FlexModule} from "@angular/flex-layout";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {FooterLandingPageComponent} from "./footer-landing-page.component";
import {DialogModule} from "primeng/dialog";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";

@NgModule({
  declarations: [
    FooterLandingPageComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FlexModule,
    ReactiveFormsModule,
    FormsModule,

    //**PrimeNG**//
    DialogModule,
    ButtonModule,
    RippleModule,
  ],
  exports: [
    FooterLandingPageComponent
  ],
  providers: []
})
export class FooterLandingPageModule {
}
