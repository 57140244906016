import {Input, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FlexModule} from "@angular/flex-layout";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import {CommonModule} from "@angular/common";
import {BadgeModule} from "primeng/badge";
import {MenuComponent} from "./menu.component";


const routes: Routes = [
  {path: '', component: MenuComponent}
];

@NgModule({
  declarations: [
    MenuComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FlexModule,
    ReactiveFormsModule,
    FormsModule,

    //**PrimeNG**//
    BadgeModule,
    ButtonModule,

  ],
  exports: [
    MenuComponent
  ],
  providers: []
})
export class MenuModule {
}
