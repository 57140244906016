import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { RippleModule } from 'primeng/ripple';
import { CarouselComponentModule } from 'src/app/components/carousel/carousel-component.module';
import { ProductCarouselComponentModule } from 'src/app/components/products-carousel-component/product-carousel-component.module';
import { ProductCardPage } from './product-card.page';

@NgModule({
  declarations: [ProductCardPage],
  imports: [
    CommonModule,
    RouterModule,
    FlexModule,
    FormsModule,
    CurrencyMaskModule,

    //**PrimeNG**//
    DialogModule,
    ButtonModule,
    RippleModule,

    //**Components**/
    CarouselComponentModule,
    ProductCarouselComponentModule,
  ],
  exports: [ProductCardPage],
  providers: [],
})
export class ProductCardModule {}
