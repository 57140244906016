import { Injectable } from '@angular/core';
import { BaseWS } from './base.ws';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '../environment.service';
import { UserNotification } from '../../model/user-notification';
import { Observable } from 'rxjs';

@Injectable()
export class UserNotificationWS extends BaseWS<UserNotification> {

  constructor(
    protected override httpClient: HttpClient,
    protected override env: EnvironmentService
  ) {
    super('user', '/user-notification', httpClient, env);
  }

  findByUser(): Observable<UserNotification[]> {
    return this.httpClient.get<UserNotification[]>(`${this.resourceRequestPath()}/user/`);
  }

  countByUser(): Observable<number> {
    return this.httpClient.get<number>(`${this.resourceRequestPath()}/count/user/`);
  }

  updateReadByUserId(): Observable<number> {
    return this.httpClient.put<number>(`${this.resourceRequestPath()}/read/`, null);
  }

  updateReadById(id: number): Observable<number> {
    return this.httpClient.put<number>(`${this.resourceRequestPath()}/${id}/read/`, null);
  }
}
