import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GlobalVarsService} from '../services/global-vars.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private globalVars: GlobalVarsService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: this.headers()
    });
    return next.handle(request);
  }

  private headers(): any {
    const headers: any = {};
    const token = this.globalVars.getToken();
    if (token != null && token != undefined && token != "undefined") {
      headers.Authorization = `Bearer ${token}`;
    }
    return headers;
  }

}
