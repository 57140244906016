import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-coin-quote',
  templateUrl: './coin-quote.page.html',
  styleUrls: ['./coin-quote.page.scss'],
})
export class CoinQuotePage implements OnInit {
  constructor() {}

  @Input() item: any;
  @Input() type: string = ''; // types: 'primary' and 'secondary', default is primary
  @Input() artistType: string = ''; // types: 'primary' and 'secondary', default is primary
  @Input() goTo: string = '';
  @Input() backTo: string = null;

  trend: number = 0;
  resultTrend: string = '';

  ngOnInit(): void {
    this.coinTrend();
  }

  coinTrend() {
    this.trend = parseFloat(
      ((parseFloat(this.item.currentQuote) * 100) / parseFloat(this.item.lastQuote) - 100).toFixed(2),
    );
    this.resultTrend = this.trend.toString().replace('.', ',');
  }

  getColorTag(trend: number): string {
    if (trend > 0) return 'coin-quote-tag-up';
    if (trend < 0) return 'coin-quote-tag-down';
    return 'coin-quote-tag-neutral';
  }

  getIconTag(trend: number): string {
    if (trend > 0) return 'pi-arrow-up-right';
    if (trend < 0) return 'pi-arrow-down-left';
    return 'pi-minus';
  }
}
