import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Amplify, Auth } from 'aws-amplify';
import { environment } from '../../../environments/environment';
import { Login, IamUser } from '../model/iam-user';
import { GlobalVarsService } from './global-vars.service';
import { CognitoUser } from 'amazon-cognito-identity-js';

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  private authenticationSubject: BehaviorSubject<any>;

  constructor(private globalVars: GlobalVarsService) {
    Amplify.configure({
      Auth: environment.cognito,
    });

    this.authenticationSubject = new BehaviorSubject<boolean>(false);
  }

  public signUp(email: string, password, attributes: any): Promise<any> {
    return Auth.signUp({
      username: email.trim(),
      password: password.trim(),
      attributes: attributes,
    });
  }

  public resendSignUp(username: string): Promise<any> {
    return Auth.resendSignUp(username);
  }

  public confirmSignUp(username: string, code: string): Promise<any> {
    return Auth.confirmSignUp(username, code);
  }

  public signIn(user: Login): Promise<any> {
    return Auth.signIn(user.email.trim(), user.password.trim()).then((res) => {
      // this.globalVars.setLoggedUser(res.attributes, res.signInUserSession.accessToken.jwtToken);
      this.globalVars.setLoggedUser(res.attributes, res.signInUserSession.idToken.jwtToken);
      this.authenticationSubject.next(true);
    });
  }

  public signOut(): Promise<any> {
    return Auth.signOut().then(() => {
      this.globalVars.clear();
      this.authenticationSubject.next(false);
    });
  }

  public forgotPassword(username: string): Promise<any> {
    return Auth.forgotPassword(username).then(() => {
      // console.log('Enviou e-mail');
    });
  }

  public forgotPasswordSubmit(username: string, code: string, password: string): Promise<any> {
    return Auth.forgotPasswordSubmit(username, code, password);
  }

  public changePassword(user: CognitoUser, oldPassword: string, newPassword: string): Promise<any> {
    return Auth.changePassword(user, oldPassword, newPassword);
  }

  public deleteUser(): Promise<any> {
    return Auth.deleteUser();
  }

  public isAuthenticated(): Promise<boolean> {
    if (this.authenticationSubject.value) {
      return Promise.resolve(true);
    } else {
      return this.getUser()
        .then((user: any) => {
          return !!user;
        })
        .catch(() => {
          return false;
        });
    }
  }

  public getUser(): Promise<any> {
    return Auth.currentUserInfo();
  }

  public getCurrentAuthenticatedUser(): Promise<any> {
    return Auth.currentAuthenticatedUser();
  }

  public updateUser(user: IamUser): Promise<any> {
    return Auth.currentUserPoolUser().then((cognitoUser: any) => {
      return Auth.updateUserAttributes(cognitoUser, user);
    });
  }
}
