import { File, UploadedFile } from '../../model/dto/image-upload.dto';
import { Observable } from 'rxjs';
import {Injectable} from '@angular/core';
import {BaseWS} from './base.ws';
import {HttpClient} from '@angular/common/http';
import {EnvironmentService} from '../environment.service';
import {Artist} from '../../model/artist';

@Injectable()
export class ArtistWS extends BaseWS<Artist> {

  constructor(
    protected override httpClient: HttpClient,
    protected override env: EnvironmentService
  ) {
    super('artist', '/artist', httpClient, env);
  }

  findSequence(): Observable<Artist[]> {
    return this.httpClient.get<Artist[]>(`${this.resourceRequestPath()}/sequence/`);
  }

  imageUpload(files: File[]): Observable<UploadedFile[]> {
    return this.httpClient.post<UploadedFile[]>(`${this.resourceRequestPath()}/files/`, files);
  }
}
