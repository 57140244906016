import { Router } from '@angular/router';
import { ConfigurationWS } from '../services/ws/configuration.ws';

export async function checkMaintenance(router: Router, configurationWS: ConfigurationWS) {
  configurationWS.findStatus().subscribe({
    next: (response) => {
      if (response.failing) {
        router.navigate(['/maintenance']);
      }
    },
    error: (error) => {
      console.log(error);
    }
  });
}
