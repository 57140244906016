import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-artist-card',
  templateUrl: './artist-card.page.html',
  styleUrls: ['./artist-card.page.scss']
})
export class ArtistCardPage implements OnInit {

  @Input() name: string = '';
  @Input() imageUrl: string = '';
  @Input() coin: string = '';
  @Input() type: string = ''; // types: 'primary' and 'secondary', default is primary

  constructor() { }

  ngOnInit(): void {
  }

}
