import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-product-carousel-component',
  templateUrl: './product-carousel-component.page.html',
  styleUrls: ['./product-carousel-component.page.scss'],
})
export class ProductCarouselComponentPage implements OnInit {
  @Input() items = [];
  @Input() showMiniature = false;
  @Input() showNavigation = false;
  @Input() autoplay = false;
  @Input() showGradient = false;
  @Input() timeToChange = 5; //seconds
  @Input() slide = false;
  @Input() photoIndex: number = 0;

  selectedindex: number = 0;
  customOptions: OwlOptions = {
    mouseDrag: true,
    startPosition: this.photoIndex - 1,
    touchDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    autoWidth: true,
    center: true,
    loop: true,
    nav: this.showNavigation,
    responsive: {
      300: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
      1500: {
        items: 1,
      },
    },
  };

  constructor() {}

  ngOnInit() {
    if (this.autoplay) this.showSlides();
    this.customOptions = { ...this.customOptions, autoplay: this.autoplay, startPosition:  this.photoIndex - 1 };
    this.customOptions = { ...this.customOptions, autoplayTimeout: this.timeToChange * 1000,  startPosition:  this.photoIndex - 1 };
  }

  selectImage(index: number) {
    this.selectedindex = index;
  }

  showSlides() {
    let count = 0;
    const i = setInterval(() => {
      if (count === this.items.length - 1) {
        this.showSlides();
        clearInterval(i);
      }
      this.selectImage(count);
      count++;
    }, this.timeToChange * 1000);
  }

  getPrevSlide() {
    this.selectedindex = this.selectedindex === 0 ? this.items.length - 1 : this.selectedindex - 1;
  }

  getNextSlide() {
    this.selectedindex = this.selectedindex === this.items.length - 1 ? 0 : this.selectedindex + 1;
  }
}
