<p-sidebar
  [(visible)]="sideMenu"
  [baseZIndex]="1000"
  [dismissible]="true"
  [modal]="true"
  appendTo="body"
  [showCloseIcon]="false"
>
  <div class="closer" fxLayout="row" fxLayoutAlign="end center">
    <a (click)="sideMenu = false">
      <i class="pi pi-bars p-mr-3" id="icon-bar">
        <div *ngIf="+notifications > 0">
          <p-badge [value]="notifications" styleClass="mr-2" class="badge-btn-menu" *ngIf="isLoggedIn"></p-badge>
        </div>
      </i>
    </a>
  </div>

  <div class="menu">
    <div (click)="sideMenu = false">
      <app-menu notification="{{ notifications }}"></app-menu>
    </div>
  </div>
</p-sidebar>

<div class="position-header">
  <div fxLayout="row" class="layout-header">
    <div fxFlex class="start" fxLayout="row" fxLayoutAlign="start start">
      <div [routerLink]="['/home']">
        <img alt="Card" src="assets/images/logo-orka.svg" class="logo-header" />
      </div>
      <div class="header-label" [routerLink]="['/home']" (click)="RemoveSelection()">
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center" class="btn-header">
      <div fxFlex class="selectBtn" id="btn-header">
        <div class="btnMoeda">
          <p-selectButton
            fxFlex
            fxFlexAlign="center"
            [options]="stateOptions2"
            [(ngModel)]="value1"
            optionLabel="label"
            optionValue="value"
            [routerLink]="['/user-wallet']"
          ></p-selectButton>
        </div>

        <div fxFlex fxFlexAlign="start center" class="btnLoja">
          <p-selectButton
            fxFlex
            fxFlexAlign="center"
            [options]="stateOptions1"
            [(ngModel)]="value1"
            optionLabel="label"
            optionValue="value"
            [routerLink]="['/home-store']"
          ></p-selectButton>
        </div>

        <div fxFlex fxFlexAlign="center center" class="btnMenu">
          <p-selectButton
            fxFlex
            fxFlexAlign="center"
            [options]="stateOptions3"
            [(ngModel)]="value2"
            optionLabel="label"
            optionValue="value"
            (click)="sideMenu = true"
          >
            <ng-template let-item>
              <i [class]="item.icon" id="item-icon"
                ><div *ngIf="+notifications > 0">
                  <p-badge
                    [value]="notifications"
                    styleClass="mr-2"
                    class="badge-btn-menu"
                    *ngIf="isLoggedIn"
                  ></p-badge></div
              ></i>
            </ng-template>
          </p-selectButton>
        </div>
      </div>
    </div>
  </div>
</div>
