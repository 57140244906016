import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { RouterModule, Routes } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { RippleModule } from 'primeng/ripple';
import { ArtistCircleListModule } from '../../../components/artist-circle-list/artist-circle-list.module';
import { CarouselComponentModule } from '../../../components/carousel/carousel-component.module';
import { FooterLandingPageModule } from '../../../components/footer-landing-page/footer-landing-page.module';
import { FooterModule } from '../../../components/footer/footer.module';
import { ArtistWS } from '../../../shared/services/ws/artist.ws';
import { CoinWS } from '../../../shared/services/ws/coin.ws';
import { ProductPromotionWS } from '../../../shared/services/ws/product-promotion.ws';
import { CoinQuoteListModule } from '../../broker/components/coin-quote-list/coin-quote-list.module';
import { ProductCardListModule } from '../../store/components/product-card-list/product-card-list.module';
import { ChatButtonModule } from '../components/cart-button/chat-button.module';
import { HomePage } from './home.page';
import { ConfigurationWS } from 'src/app/shared/services/ws/configuration.ws';

const routes: Routes = [{ path: '', component: HomePage }];

@NgModule({
  declarations: [HomePage],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FlexModule,
    NgxSpinnerModule,
    NgxSkeletonLoaderModule,

    //**Components**//
    ProductCardListModule,
    CoinQuoteListModule,
    FooterLandingPageModule,
    FooterModule,
    ArtistCircleListModule,
    CarouselComponentModule,
    ChatButtonModule,

    //**PrimeNG**//
    RippleModule,
    ButtonModule,
    AccordionModule,
    DividerModule,
  ],
  providers: [ArtistWS, ProductPromotionWS, CoinWS, ConfigurationWS],
  exports: [HomePage],
})
export class HomeModule {}
