<div *ngIf="type==='primary'" class="product-card">
  <div class="product-card-content">
    <div class="product-card-img">
      <img *ngIf="item?.photo !== null" class="product-img" [src]="item?.photo[0]?.fileUrl" [alt]="item.name" />
      <img *ngIf="item?.photo === null" src="../../../../../assets/images/gift-box.svg" [alt]="item.name" />
    </div>

    <span class="product-card-name"> {{item.name}} </span>

    <div class="product-info" *ngIf="currentCurrency !== 'R$'">
      <span class="product-card-price-coin">
        {{ parseValue(getPriceCoin().toFixed(2)) | currency:currentCurrency }}
      </span>
    </div>

    <div *ngIf="!getPriceCoin()">
      <span class="product-card-price">
        {{(parseValue(item.promotionValue?item.promotionValue:item.value).toFixed(2)) | currency:'BRL'}}
      </span>
    </div>

    <div *ngIf="item.storeName" class="product-card-seller">
      Vendido e entregue por <span> {{item.storeName}}</span>
    </div>
  </div>

  <div class="product-actions">
    <div class="product-cart-actions" *ngIf="showShoppingCartDetails">
      <button type="button" class="removeBtn pointer" (click)="removeItemFromCart()">Remover da cesta</button>
      <div class="product-add" fxLayout="row">
        <i class="pi pi-minus decremetion pointer" (click)="alterProductQuantity('subtract')"></i>
        <input
          readonly
          type="number"
          id="productQuantity"
          [ngModel]="productQuantity"
          (ngModelChange)="atProductQuantityChange($event)"
        />
        <i class="pi pi-plus incremetion pointer" (click)="alterProductQuantity('add')"></i>
      </div>
    </div>
    <div *ngIf="showButtonDetails" class="product-card-footer">
      <button
        pButton
        pRipple
        type="button"
        [label]="item.quantity > 0 ? 'COMPRAR' : 'INDISPONÍVEL'"
        class="p-button-rounded"
        [routerLink]="['/store/add-to-cart/', item.id]"
        [state]="{data: {isCategory: isCategory}}"
        [disabled]="item.quantity <= 0"
      ></button>
    </div>
  </div>
</div>

<div *ngIf="type==='secondary'" [routerLink]="['/store/add-to-cart/', item?.productId]" class="pointer">
  <div fxLayout="row" class="buy-card">
    <div *ngIf="hasFreeFreight()" class="div-free-shipping">
      <h1 class="text-free-shipping">FRETE GRÁTIS</h1>
    </div>
    <div class="img-sales">
      <img [src]="item?.photo[0]?.fileUrl" />
    </div>
    <div>
      <h1 class="sale-product">{{item.name}}</h1>
    </div>
    <div>
      <h1 class="sale-price">{{ parseValue(getPriceCoin().toFixed(2)) | currency: currentCurrency}}</h1>
    </div>
    <div *ngIf="item.storeName" class="product-card-seller">
      Vendido e entregue por <span> {{item.storeName}} </span>
    </div>
  </div>
</div>

<div *ngIf="type === 'tertiary'" [routerLink]='["/store/add-to-cart/", item.id]' class="pointer">
  <div fxLayout="column" fxLayoutGap="10" class="buy-card-promotion">
    <div class="div-free-shipping">
      <h1 class="text-free-shipping">FRETE GRÁTIS</h1>
    </div>

    <div class="img-sales">
      <img [src]="item?.photo[0]?.fileUrl" />
    </div>

    <div>
      <h1 class="sale-product">{{item.name}}</h1>
    </div>

    <div>
      <h1 class="sale-price">
        {{parseValue(item.promotionValue?item.promotionValue:item.value).toFixed(2) | currency:'BRL'}}
      </h1>
    </div>

    <div *ngIf="item.storeName" class="product-card-seller">
      Vendido e entregue por <span> {{item.storeName}}</span>
    </div>

    <div class="product-card-footer-tertiary">
      <button
        pButton
        pRipple
        type="button"
        label="SAIBA MAIS"
        class="p-button-rounded"
        [routerLink]="['/store/add-to-cart/', item.productId]"
      ></button>
    </div>
  </div>
</div>

<div *ngIf="type==='myOrders'" [routerLink]="['/store/my-orders/', item?.id]" class="product-card">
  <div
    class="order-number"
    fxLayout="column"
    style="justify-content: space-around; height: 90px; min-width: 270px; align-items: flex-start"
  >
    <div fxLayout="row" style="justify-content: space-around">
      <h1>Número do Pedido:</h1>
      <span class="order-status"> &nbsp;#{{item.id}}</span>
    </div>
    <div fxLayout="row" style="justify-content: space-around">
      <h1 class="order-status" *ngIf="item.status">Status do pedido:</h1>
      <span class="order-status"> &nbsp; {{getProductStatus()}}</span>
    </div>
    <div fxLayout="row" style="justify-content: space-around">
      <h1 class="order-status">Data de Emissão:</h1>
      <span class="order-status">
        &nbsp; {{item.createdAt | date:"dd/MM/yyyy"}} às {{item.createdAt | date:"HH:mm"}}</span
      >
    </div>
  </div>
  <!-- <div class="order-details"  fxLayout="column" style="justify-content: space-around; height: 90px;">

    </div> -->

  <div class="product-card-footer">
    <button
      pButton
      pRipple
      type="button"
      label="DETALHES"
      class="p-button-rounded"
      [routerLink]="['/store/my-orders/', item.id]"
    ></button>
  </div>
</div>

<div *ngIf="type==='myOrdersDetails'" [routerLink]="['/store/my-orders/', item?.id]" class="my-order-card">
  <div class="my-order-img">
    <img class="order-img" [src]="item?.photo[0]?.fileUrl" />
  </div>

  <div class="my-order-name">
    <span class="order-product-name">{{item.productName}}</span>
    <span>Quantidade: {{item.quantity}} </span>
    <span>Valor Unitário: {{productValue | currency: 'BRL'}} </span>
  </div>

  <div *ngIf="showButtonDetails" class="buy-again-footer">
    <button
      pButton
      pRipple
      type="button"
      label="Comprar novamente"
      class="buy-again-button"
      [routerLink]="['/store/add-to-cart/', item.productId]"
    >
      <i class="pi pi-chevron-right"></i>
    </button>
  </div>

  <div class="product-card-footer"></div>
</div>
