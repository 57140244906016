import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { GlobalVarsService } from 'src/app/shared/services/global-vars.service';
import { CoinWS } from 'src/app/shared/services/ws/coin.ws';

@Component({
  selector: 'app-product-card-list',
  templateUrl: './product-card-list.page.html',
  styleUrls: ['./product-card-list.page.scss'],
})
export class ProductCardListPage implements OnInit {
  @Input() items: any;
  @Input() type: string = ''; // types: 'primary', 'secondary' and 'tertiary' , default is primary
  @Input() showButtonDetails: boolean = false;
  @Input() showShoppingCartDetails: boolean = false;
  @Input() currentCurrency = 'R$';
  @Input() currentQuote = 1;

  @Output() changeItems: EventEmitter<any[]>;

  constructor(
    private coinWS: CoinWS,
    private globalVarsService: GlobalVarsService) {
    this.changeItems = new EventEmitter();
  }
  ngOnInit(): void {
    this.loadCoin()
  }

  loadCoin() {
    const coinId = this.globalVarsService.getCoinSelected();
    this.coinWS.findById(coinId).subscribe({
      next: (data) => {
        this.currentQuote = data.currentQuote;
        this.currentCurrency = data.acronym;
      },
      error: (e) => console.error(e),
    });
  }

  removeItem(id: number) {
    const index = this.items.findIndex((product) => product.id == id);
    this.items.splice(index, 1);
    this.changeItems.emit(this.items);
  }

  changeProductQuantity({ id, productQuantity }) {
    const index = this.items.findIndex((product) => product.id == id);
    this.items[index].userProductQuantity = productQuantity;
    this.changeItems.emit(this.items);
  }
}
