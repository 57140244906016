import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PageDTO } from '../../model/dto/page.dto';
import { EnvironmentService } from '../environment.service';

export abstract class BaseWS<T extends { id?: number }> {
  protected constructor(
    protected microservicePath: string,
    protected resourcePath: string,
    protected httpClient: HttpClient,
    protected env: EnvironmentService,
  ) {}

  findAll(): Observable<T[]> {
    return this.httpClient.get<T[]>(`${this.resourceRequestPath()}/`);
  }

  findActive(): Observable<T[]> {
    return this.httpClient.get<T[]>(`${this.resourceRequestPath()}/active/`);
  }

  findById(id: number): Observable<T> {
    return this.httpClient.get<T>(`${this.resourceRequestPath()}/${id}/`);
  }

  findPageOptions(
    firstIndex: number,
    size: number,
    optionParams: any,
    sort?: string,
    dir?: string,
  ): Observable<PageDTO<T>> {
    const page = firstIndex / size;
    const paramsFilter = optionParams ? optionParams : {};
    const paramsPaginator = { page: page, size: size, sort: sort ? sort : 'id', dir: dir ? dir : 'asc' };
    const options = {
      params: {
        ...paramsPaginator,
        ...paramsFilter,
      },
    };
    return this.httpClient.get<PageDTO<T>>(`${this.resourceRequestPath()}/paged/`, options);
  }

  save(t: T): Observable<number | void> {
    if (this.isNew(t)) {
      return this.httpClient.post<number>(`${this.resourceRequestPath()}/`, t);
    } else {
      return this.httpClient.put<void>(`${this.resourceRequestPath()}/${this.id(t)}/`, t);
    }
  }

  activate(id: number, active: boolean): Observable<void> {
    return this.httpClient.put<void>(`${this.resourceRequestPath()}/${id}/active/`, { active: active });
  }

  delete(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.resourceRequestPath()}/${id}/`);
  }

  private id(t?: T): number | null {
    if (t && t.id) {
      return t.id;
    }
    return null;
  }

  isNew(t: T): boolean {
    return this.id(t) == null;
  }

  protected resourceRequestPath(): string {
    return `${this.env.hostURL}${this.resourcePath}`;
  }
}
