import { Injectable } from '@angular/core';
import { LoggedUser } from '../model/logged-user';
import { Product } from '../model/product';
import { parseAddresses } from '../utils/strings-utils';

@Injectable({
  providedIn: 'root',
})
export class GlobalVarsService {
  private readonly STORAGE_TOKEN = 'token';
  private readonly STORAGE_LOGGED_IN_USER = 'loggedUser';
  private readonly STORAGE_REGISTER_USER = 'registerUser';
  private readonly STORAGE_SHOPPING_CART = 'shoppingCart';
  private readonly STORAGE_CHECKOUT_DATA = 'checkoutData';
  private readonly STORAGE_EMAIL_FORGOT_PASSWORD = 'emailForgotPassword';
  private readonly STORAGE_COIN = 'coin';
  private readonly STORAGE_OFFER = 'offer';
  private readonly STORAGE_USER_DOCUMENT_FRONT = 'userDocumentFront';
  private readonly STORAGE_USER_DOCUMENT_BACK = 'userDocumentBack';
  private readonly DEFAUL_COIN_ID = 1;
  private readonly STORAGE_ROUTE = 'route';
  private readonly STORAGE_PRODUCT_ID = 'productId';

  constructor() {}

  get isLoggedIn(): boolean {
    return this.getToken() != null;
  }

  public getToken(): string | null {
    return localStorage.getItem(this.STORAGE_TOKEN);
  }

  public setToken(token: string): void {
    localStorage.setItem(this.STORAGE_TOKEN, token);
  }

  public clearToken(): void {
    localStorage.removeItem(this.STORAGE_TOKEN);
  }

  public getEmailForgotPassword(): string | null {
    return localStorage.getItem(this.STORAGE_EMAIL_FORGOT_PASSWORD);
  }

  public setEmailForgotPassword(email: string): void {
    localStorage.setItem(this.STORAGE_EMAIL_FORGOT_PASSWORD, email);
  }

  public clearEmailForgotPassword(): void {
    localStorage.removeItem(this.STORAGE_EMAIL_FORGOT_PASSWORD);
  }

  public getRegisterUser(): any {
    const user = localStorage.getItem(this.STORAGE_REGISTER_USER);
    return user == null ? null : JSON.parse(user);
  }

  public setRegisterUser(user: any): void {
    localStorage.setItem(this.STORAGE_REGISTER_USER, JSON.stringify(user));
  }

  public clearRegisterUser(): void {
    localStorage.removeItem(this.STORAGE_REGISTER_USER);
  }

  public getLoggedUser(): LoggedUser {
    const loggedUser = localStorage.getItem(this.STORAGE_LOGGED_IN_USER);
    return loggedUser == null ? null : (JSON.parse(loggedUser) as LoggedUser);
  }

  public setLoggedUser(user: LoggedUser, token: string): void {
    this.setToken(token);

    const parsedAddresses = typeof user.addresses === 'string'
      ? parseAddresses(user.addresses)
      : user.addresses;
    localStorage.setItem(this.STORAGE_LOGGED_IN_USER, JSON.stringify({ ...user, addresses: parsedAddresses }));
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    if (this.getLoggedUser() == null || token == null || token == undefined || token == 'undefined') {
      return false;
    } else {
      // Split the JWT token and decode the payload
      const payloadBase64 = token.split('.')[1];
      const decodedPayload = JSON.parse(atob(payloadBase64));
      // Extract the 'exp' property (in seconds)
      const expirationTime = decodedPayload.exp;
      // Convert to milliseconds and compare with the current time
      const isExpired = Date.now() >= expirationTime * 1000;

      if (isExpired) {
        this.clearLoggedUser();
        this.clearToken();
      }

      return !isExpired;
    }
  }

  public clearLoggedUser(): void {
    localStorage.removeItem(this.STORAGE_LOGGED_IN_USER);
  }

  public clear(): void {
    localStorage.clear();
  }

  public getCoinSelected(): any {
    const coin = localStorage.getItem(this.STORAGE_COIN);
    return coin ? JSON.parse(coin) : this.DEFAUL_COIN_ID;
  }

  public setCoinSelected(coin: any): void {
    localStorage.setItem(this.STORAGE_COIN, JSON.stringify(coin ?? ''));
  }

  public clearCoinSelected(): void {
    localStorage.removeItem(this.STORAGE_COIN);
  }

  public setShoppingCart(product: any, quantity: number): void {
    let prod = [];
    let productExists = false;
    if (this.getShoppingCart() !== null) {
      prod = this.getShoppingCart();
    }
    prod.map((item) => {
      const maxQuantity = item.promotionQuantity ? item.promotionQuantity - item.promotionSoldQuantity : item.quantity;
      if (item.id == product.id) {
        productExists = true;
        item.userProductQuantity =
          item.userProductQuantity + quantity <= maxQuantity ? item.userProductQuantity + quantity : maxQuantity;
      }
    });
    if (!productExists) {
      product.userProductQuantity = quantity;
      prod.push(product);
    }

    localStorage.setItem(this.STORAGE_SHOPPING_CART, JSON.stringify(prod));
  }

  public getShoppingCart(): any | null {
    const products = localStorage.getItem(this.STORAGE_SHOPPING_CART);
    return products === null ? [] : JSON.parse(products);
  }

  updateShoppingCart(products: Product[]) {
    this.clearShoppingCart();
    let prod = [];
    prod = products.map((item) => item);
    localStorage.setItem(this.STORAGE_SHOPPING_CART, JSON.stringify(prod));
  }

  public clearShoppingCart(): void {
    localStorage.setItem(this.STORAGE_SHOPPING_CART, null);
  }

  setCheckoutData(checkoutData: any) {
    localStorage.setItem(this.STORAGE_CHECKOUT_DATA, JSON.stringify(checkoutData));
  }

  public clearCheckoutData(): void {
    localStorage.setItem(this.STORAGE_CHECKOUT_DATA, null);
  }

  public getCheckoutData(): any | null {
    const checkoutData = localStorage.getItem(this.STORAGE_CHECKOUT_DATA);
    const initial = {
      address: {},
      cart: [],
      taxValue: null,
      shipmentValue: null,
      subtotalValueCoin: null,
      totalValueCoin: null,
      totalValueMoney: null,
      currentCurrency: '',
      currentQuote: '',
    };
    return checkoutData === null ? initial : JSON.parse(checkoutData);
  }

  setOffer(offer: any) {
    localStorage.setItem(this.STORAGE_OFFER, JSON.stringify(offer));
  }

  clearOffer(): void {
    localStorage.removeItem(this.STORAGE_OFFER);
  }

  getOffer(): any {
    const offer = localStorage.getItem(this.STORAGE_OFFER);
    return JSON.parse(offer);
  }

  public getUserDocumentFront(): any | null {
    const userDocumentFront = localStorage.getItem(this.STORAGE_USER_DOCUMENT_FRONT);
    return userDocumentFront === null ? null : JSON.parse(userDocumentFront);
  }

  public setUserDocumentFront(documentFront: any): void {
    localStorage.setItem(this.STORAGE_USER_DOCUMENT_FRONT, JSON.stringify(documentFront));
  }

  public clearUserDocumentFront(): void {
    localStorage.removeItem(this.STORAGE_USER_DOCUMENT_FRONT);
  }

  public getUserDocumentBack(): any | null {
    const userDocumentBack = localStorage.getItem(this.STORAGE_USER_DOCUMENT_BACK);
    return userDocumentBack === null ? null : JSON.parse(userDocumentBack);
  }

  public setUserDocumentBack(documentBack: any): void {
    localStorage.setItem(this.STORAGE_USER_DOCUMENT_BACK, JSON.stringify(documentBack));
  }

  public clearUserDocumentBack(): void {
    localStorage.removeItem(this.STORAGE_USER_DOCUMENT_BACK);
  }

  public setPageRoute(route: string): void {
    localStorage.setItem(this.STORAGE_ROUTE, JSON.stringify([route]));
  }

  public getPageRoute(): string[] | null {
    const pageRoute = localStorage.getItem(this.STORAGE_ROUTE);
    return JSON.parse(pageRoute);
  }

  public clearPageRoute(): void {
    localStorage.removeItem(this.STORAGE_ROUTE);
  }

  public setProductId(productId: number): void {
    localStorage.setItem(this.STORAGE_PRODUCT_ID, JSON.stringify(productId));
  }

  public getProductId(): number | null {
    const productId = localStorage.getItem(this.STORAGE_PRODUCT_ID);
    return JSON.parse(productId);
  }

  public clearProductId(): void {
    localStorage.removeItem(this.STORAGE_PRODUCT_ID);
  }
}
