import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { GlobalVarsService } from '../services/global-vars.service';

@Injectable({
  providedIn: 'root',
})
export class NotLoggedInGuard implements CanActivate {
  constructor(private router: Router, private globalVars: GlobalVarsService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const emailForgotPassword = this.globalVars.getEmailForgotPassword();
    const userRegister = this.globalVars.getRegisterUser();
    const userDocumentFront = this.globalVars.getUserDocumentFront();
    const userDocumentBack = this.globalVars.getUserDocumentBack();

    if (!this.globalVars.isLoggedIn) {
      // console.log(state.url)

      if ((state.url == '/reset-password' || state.url == '/reset-password-success') && emailForgotPassword == null) {
        this.router.navigateByUrl('/send-email');
        return false;
      }

      if (state.url == '/user-wallet' || state.url == '/store/shopping-cart') {
        this.router.navigateByUrl('/login');
        return false;
      }

      if (
        state.url == '/register-user/personal-info' ||
        state.url == '/register-user/phone-info' ||
        state.url == '/register-user/verify-info' ||
        (state.url == '/register-user/successfully-registered' && userRegister == null)
      ) {
        this.router.navigateByUrl('/register-user/login-info');
        return false;
      }

      if (state.url == '/register-user/personal-info' && (userRegister.email == null || userRegister.password)) {
        this.router.navigateByUrl('/register-user/login-info');
        return false;
      }

      if (
        state.url == '/register-user/phone-info' &&
        (userRegister.email == null ||
          userRegister.password ||
          userRegister.name ||
          userRegister.birthdate ||
          userRegister.cpf)
      ) {
        this.router.navigateByUrl('/register-user/login-info');
        return false;
      }

      if (
        state.url == '/register-user/verify-info' &&
        (userRegister.email == null ||
          userRegister.password ||
          userRegister.name ||
          userRegister.birthdate ||
          userRegister.cpf ||
          userRegister.phoneNumber)
      ) {
        this.router.navigateByUrl('/register-user/login-info');
        return false;
      }

      if (
        state.url == '/register-user/successfully-registered' &&
        (userRegister.email == null ||
          userRegister.password ||
          userRegister.name ||
          userRegister.birthdate ||
          userRegister.cpf ||
          userRegister.phoneNumber ||
          userRegister.code)
      ) {
        this.router.navigateByUrl('/register-user/login-info');
        return false;
      }

      if (state.url == '/broker/bank-details' || state.url == '/broker/deposit-guidance') {
        this.router.navigateByUrl('/login');
        return false;
      }

      return true;
    } else {
      // console.log(state.url)

      if (state.url == '/profile/send-document-back' && userDocumentFront == null) {
        this.router.navigateByUrl('profile/send-document-list');
        return false;
      }

      if (state.url == '/profile/send-document-confirm' && userDocumentBack == null) {
        this.router.navigateByUrl('profile/send-document-back');
        return false;
      }

      if (state.url == '/profile/send-document-complete' ) {
        this.router.navigateByUrl('profile/send-document-complete');
        return false;
      }

      return true;
    }
    this.router.navigateByUrl('/');
    return false;
  }
}
