import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { ChatButtonComponent } from './chat-button.component';

@NgModule({
  declarations: [ChatButtonComponent],
  imports: [
    CommonModule,
    RouterModule,
    FlexModule,

    //**PrimeNG**//
    RadioButtonModule,
    BadgeModule,
    ButtonModule,
    RippleModule,
  ],
  exports: [ChatButtonComponent],
  providers: [],
})
export class ChatButtonModule {}
