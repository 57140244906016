import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-coin-quote-list',
  templateUrl: './coin-quote-list.page.html',
  styleUrls: ['./coin-quote-list.page.scss']
})
export class CoinQuoteListPage implements OnInit {

  constructor() { }


  @Input() items: any;
  @Input() type: any;
  @Input() goTo: string;
  @Input() backTo: string;

  ngOnInit(): void {  }

}
