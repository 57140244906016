<p-dialog
  [(visible)]="showDialog"
  [position]="'bottom'"
  [draggable]="false"
  [resizable]="false"
  [modal]="false"
  [baseZIndex]="10000"
  [closable]="false"
  class="footer-dialog"
>
  <div class="dialog-button" fxLayoutAlign="center" fxLayout="row" fxLayoutGap="20">
    <div fxLayout="row" fxLayoutGap="15px" class="buttonFooter">
      <button
        pButton
        pRipple
        type="button"
        label="ENTRAR"
        class="p-button-rounded tamEntrar"
        [routerLink]="['/login']"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        label="CRIAR CONTA"
        class="p-button-rounded tamCriar"
        [routerLink]="['/register-user']"
      ></button>
    </div>
  </div>
</p-dialog>
