<div *ngIf="type==='primary'" class="artist">
  <div class="artist-img-profile">
    <img [src]="imageUrl" alt="">
  </div>
  <div class="artist-info-profile">
    <span class="artist-name-profile">{{name}}</span>
    <label class="artist-coin-profile">{{coin}}</label>
  </div>
</div>

<div *ngIf="type==='secondary'" class="artist-full">
  <div class="artist-img-profile">
    <img [src]="imageUrl" alt="">
  </div>

  <div fxLayout="row">
    <div fxFlex fxLayoutAlign="start center" class="padding-left">
      <span class="artist-name-inline">{{name}} </span>
      <label class="artist-coin-inline padding-left">({{coin}})</label>
    </div>
  </div>
</div>

