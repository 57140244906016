import {Component, OnInit, Input} from '@angular/core';
import { Artist } from 'src/app/shared/model/artist';

@Component({
  selector: 'app-artist-circle',
  templateUrl: './artist-circle.component.html',
  styleUrls: ['./artist-circle.component.scss']
})
export class ArtistCircleComponent implements OnInit {

  @Input() item: Artist;
  routerLink: string[];

  constructor() { }

  ngOnInit(): void {
    this.routerLink = this.item?.coinId ? ['/broker/coin-store'] : []
  }

}
