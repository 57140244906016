import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FlexModule} from "@angular/flex-layout";
import {CommonModule} from "@angular/common";
import {CarouselModule} from "ngx-owl-carousel-o";
import {CarouselComponentPage} from "./carousel-component.page";

@NgModule({
  declarations: [
    CarouselComponentPage,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FlexModule,

    //**Components**//
    CarouselModule,

    //**PrimeNG**//

  ],
  exports: [
    CarouselComponentPage
  ],
  providers: []
})
export class CarouselComponentModule {
}
