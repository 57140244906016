import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CognitoService } from '../services/cognito.service';
import { MessageService } from '../services/message.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private cognitoService: CognitoService,
    private spinner: NgxSpinnerService,
    private message: MessageService,
    private router: Router,
  ) {}

  count = 0;
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if ([401, 403].indexOf(err.status) !== -1) {
          this.cognitoService.signOut().then(() => this.router.navigate(['/login']));
          if (this.count < 1) {
            this.showErrorMessage(err);
          }
          this.count++;
          return new Observable<HttpEvent<any>>();
        }

        return throwError(err);
      }),
    );
  }

  /**
   * Mostra a mensagem de erro somente se não mostrou uma mensagem igual há pouco tempo.
   */
  private showErrorMessage(err: any): void {
    // Se nao tem mensagem, entao mostra a padrão.
    this.spinner.hide();
    if (err?.error?.message) {
      this.message.showInvalidAccess();
    } else {
      this.message.showError(err.error.message);
    }
  }
}
