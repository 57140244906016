import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FlexModule} from "@angular/flex-layout";
import {CommonModule} from "@angular/common";
import {DialogModule} from "primeng/dialog";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {ProductCardListPage} from "./product-card-list.page";
import {ProductCardModule} from "../product-card/product-card.module";

@NgModule({
  declarations: [
    ProductCardListPage,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FlexModule,

    //**PrimeNG**//
    DialogModule,
    ButtonModule,
    RippleModule,
    ProductCardModule,
  ],
  exports: [
    ProductCardListPage,
  ],
  providers: []
})
export class ProductCardListModule {
}
