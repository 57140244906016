import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-carousel-component',
  templateUrl: './carousel-component.page.html',
  styleUrls: ['./carousel-component.page.scss'],
})
export class CarouselComponentPage implements OnInit, OnChanges {
  @Input() items = [];
  @Input() showMiniature = false;
  @Input() showNavigation = false;
  @Input() autoplay = false;
  @Input() showGradient = false;
  @Input() timeToChange = 5; //seconds
  @Input() slide = false;
  @Input() openSlides: Function;

  touchStartX: number;
  selectedindex: number = 0;
  customOptions: OwlOptions = {
    mouseDrag: true,
    touchDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    autoWidth: true,
    center: true,
    loop: true,
    nav: this.showNavigation,
    responsive: {
      300: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
      1500: {
        items: 1,
      },
    },
  };

  constructor() {}

  ngOnInit() {
    if (this.autoplay) this.showSlides();
    this.customOptions = { ...this.customOptions, autoplay: this.autoplay };
    this.customOptions = { ...this.customOptions, autoplayTimeout: this.timeToChange * 1000 };
  }

  selectImage(index: number) {
    this.selectedindex = index;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['items'] && !changes['items'].firstChange) {
      this.selectedindex = 0;
    }
  }

  showSlides() {    
    let count = 0;

    const i = setInterval(() => {
      if (count === this.items.length - 1) {
        this.showSlides();
        clearInterval(i);
      }
      this.selectImage(count);
      count++;
    }, this.timeToChange * 1000);
  }

  getPrevSlide() {
    this.selectedindex = this.selectedindex === 0 ? this.items.length - 1 : this.selectedindex - 1;
  }

  getNextSlide() {
    this.selectedindex = this.selectedindex === this.items.length - 1 ? 0 : this.selectedindex + 1;
  }

  onTouchStart(event: TouchEvent) {
    this.touchStartX = event.touches[0].clientX;
  }

  onTouchEnd(event: TouchEvent) {
    const touchEndX = event.changedTouches[0].clientX;
    const touchDelta = touchEndX - this.touchStartX;

    if (touchDelta > 0) {
      // swipe para a direita
      this.getPrevSlide();
    } else {
      // swipe para a esquerda
      this.getNextSlide();
    }
  }
}
