import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ProductCarouselComponentPage } from './product-carousel-component.page';

@NgModule({
  declarations: [ProductCarouselComponentPage],
  imports: [
    CommonModule,
    RouterModule,
    FlexModule,

    //**Components**//
    CarouselModule,

    //**PrimeNG**//
  ],
  exports: [ProductCarouselComponentPage],
  providers: [],
})
export class ProductCarouselComponentModule {}
