import { CognitoService } from './../../../../shared/services/cognito.service';
import { FavoriteArtistWS } from './../../../../shared/services/ws/favorite-artist.ws';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FlexModule} from "@angular/flex-layout";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {FavoriteHeaderArtistComponent} from "./favorite-header-artist.component";
import {ArtistCardModule} from "../artist/artist-card.module";
import { CircleSpinnerModule } from "../../../../components/circle-spinner/circle-spinner.module";

@NgModule({
    declarations: [
        FavoriteHeaderArtistComponent,
    ],
    exports: [
        FavoriteHeaderArtistComponent
    ],
    providers: [
        FavoriteArtistWS,
        CognitoService
    ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        FlexModule,
        //**Components**//
        ArtistCardModule,
        CircleSpinnerModule
    ]
})
export class FavoriteHeaderArtistModule {
}
