import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {GlobalVarsService} from '../services/global-vars.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private globalVars: GlobalVarsService,
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.globalVars.isLoggedIn) {

      this.router.navigate(['/login'], { queryParams: { redirectTo: state.url } });
      return false;
    }

    return true;
  }

}
