import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { MessageService } from './message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CognitoService } from './cognito.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService extends ErrorHandler {
  // Mensagem padrao para erros no front
  static readonly DEFAULT_CLIENT_USER_MESSAGE: string = 'Ocorreu um erro inesperado na aplicação.';

  // Mensagem padrao para quando o erro do servidor nao tem mensagem
  static readonly DEFAULT_SERVER_USER_MESSAGE: string = 'Ocorreu um erro ao acessar o servidor.';

  // Mensagem padrao para quando nao tem internet
  static readonly DEFAULT_SERVER_USER_MESSAGE_OFFLINE: string = 'Falha na conexão. Verifique seu acesso à internet!';

  private env: EnvironmentService;
  private messageService: MessageService;

  // Erro completo convertido para string.
  private jsonError: string;

  // Mensagem que mostramos para o usuario na tela
  private userMessage: any;

  // Mensagem que veio do erro (erro de servidor ou de front), essa e o Error.message
  private errorMessage: string;

  // Stack que veio do erro (erro de servidor ou de front), eh stacktrace do erro
  private errorStack: string;

  // Codigo do erro (para erro do response do servidor)
  private errorCode: string;

  // O tipo do erro que veio (TypeError, RangeError, Excecao do Java que veio do server, etc)
  private errorName: string;

  // O nome do erro que nos tratamos no handleError (erro de response, erro de cliente, erro de observable...)
  private errorType: string;

  constructor(private injector: Injector, private spinner: NgxSpinnerService) {
    super();
    this.env = this.injector.get(EnvironmentService);
    this.messageService = this.injector.get(MessageService);
  }

  override handleError(error: any): void {
    try {
      this._handleError(error);
    } catch (e) {}
  }

  private _handleError(error: any): void {
    if (error != null) {
      this.spinner.hide();

      // Verifico se eh um erro de uma Promise
      // Esse erro vem no formato error = {promise: Object, rejection: Error, task: Object, zone: Object, message: string, stack: string}
      // Se for o erro da promise, eu pego somente o 'rejection' que eh o objeto que contem o Error
      // Caso contrario, ja eh um objeto do tipo Error
      if (error.rejection) {
        error = error.rejection;
      }

      if (error === 'Invalid time') {
        return;
      }

      if (error instanceof HttpErrorResponse) {
        this.handleServerHttpErrorResponse(error);
      } else if (error instanceof Response) {
        // Erro de servidor {status, statusText, type, url, _body = {code, message} }
        this.handleServerErrorResponse(error);
      } else if (error instanceof ErrorEvent) {
        this.handleObservableError(error);
      } else if (error instanceof DOMException) {
        this.handleWebApiError(error);
      } else {
        // A client-side or network error occurred.
        this.handleClientError(error);
      }
    }

    this.showUserErrorMessage();

    this.consoleLogError(error);
  }

  private handleServerHttpErrorResponse(httpErrorResponse: HttpErrorResponse): void {
    const httpError = httpErrorResponse || ({ error: {} } as any);
    if (typeof httpError.error === 'string') {
      httpError.error = this.parseToJSON(httpError.error);
    }
    const extraErrorMsgs =
      (httpError.message != null ? '\nHttpErrorResponse.message: ' + httpError.message : '') +
      (httpError.ok != null ? '\nHttpErrorResponse.ok: ' + httpError.ok : '') +
      (httpError.status != null ? '\nHttpErrorResponse.status: ' + httpError.status : '') +
      (httpError.statusText != null ? '\nHttpErrorResponse.statusText: ' + httpError.statusText : '') +
      (httpError.url != null ? '\nHttpErrorResponse.url: ' + httpError.url : '');

    this.handleServerError(
      httpError,
      httpError.error,
      httpError.message,
      httpError.status,
      httpError.error.path,
      httpError.error.timestamp,
      httpError.error.code,
      httpError.name,
      httpError.error.error_stack || httpError.error.errorStack,
      httpError.error.errors,
      extraErrorMsgs,
    );
  }

  private parseToJSON(str: string): any {
    try {
      return JSON.parse(str);
    } catch {
      return str;
    }
  }

  private handleServerErrorResponse(responseError: Response): void {
    let body; // json
    try {
      body = responseError.json();
    } catch (err) {
      // pode vir um xml em vez de json
      body = {
        error: responseError[`_body`],
        status: responseError.status,
      };
    }
    if (body != null) {
      this.handleServerError(
        responseError,
        body.message,
        body.error,
        body.status,
        body.path,
        body.timestamp,
        body.code,
        body.error_name,
        body.error_stack,
        body.errors,
      );
    } else {
      this.handleServerError(responseError);
    }
  }

  private handleServerError(
    httpError: HttpErrorResponse | Response,
    message?: any,
    error?,
    status?,
    path?,
    timestamp?,
    code?,
    errorName?,
    errorStack?,
    errors?,
    extraErrosMessage?,
  ): void {
    this.errorType = 'Server Error';

    this.userMessage = {
      dsErro: GlobalErrorHandlerService.DEFAULT_SERVER_USER_MESSAGE,
    } as any;

    const errStr =
      (message ? 'message: ' + message.dsErro : '') +
      (error ? '\nerror: ' + error : '') +
      (status ? '\nstatus: ' + status : '') +
      (path ? '\npath: ' + path : '') +
      (timestamp ? '\ntimestamp: ' + timestamp : '') +
      (code ? '\ncode: ' + code : '') +
      (errorName ? '\nerror_name: ' + errorName : '') +
      (errorStack ? '\nerror_stack: ' + errorStack : '') +
      (extraErrosMessage ? extraErrosMessage : '');

    this.errorCode = code ? code : null;
    this.errorName = errorName ? errorName : null;
    this.errorStack = errorStack ? errorStack : null;

    this.errorMessage =
      'status: ' +
      httpError.status +
      '\nstatusText: ' +
      httpError.statusText +
      '\nurl: ' +
      httpError.url +
      (errStr != null ? '\n' + errStr : '');

    this.userMessage = message ? message : this.userMessage;

    if (httpError.status === 401 && httpError instanceof HttpErrorResponse) {
      const zone = this.injector.get(NgZone);
      zone.run(() => {
        this.injector.get(CognitoService).signOut();
      });
    }

    // Se esta sem conexao com a internet, entao mudo a mensagem de erro.
    if (!navigator.onLine) {
      this.userMessage = {
        dsErro: GlobalErrorHandlerService.DEFAULT_SERVER_USER_MESSAGE_OFFLINE,
      } as any;
    }
  }

  private handleWebApiError(error: DOMException): void {
    this.errorType = 'Web API Error (DOMException)';

    this.userMessage = {
      dsErro: GlobalErrorHandlerService.DEFAULT_CLIENT_USER_MESSAGE,
    } as any;

    this.errorStack = error.toString();
    this.errorMessage = error.message;
    this.errorCode = error.code.toString();
    this.errorName = error.name;
  }

  /**
   * Trata dos erros que acontecem no front.
   */
  private handleClientError(error: any): void {
    this.errorType = 'Client Error';

    // Para erro de front, nao eh bom mostrar o error.message para o usuario, pois os erros sao em ingles e especificos do codigo
    // Entao mostro uma mensagem padrao
    this.userMessage = {
      dsErro: GlobalErrorHandlerService.DEFAULT_CLIENT_USER_MESSAGE,
    } as any;

    // Um Error deve vir no formato error = {message: string, stack: string}
    // Se nao tiver o message, entao nao eh um tipo Error e nao eh tratado (erro desconhecido)
    // Se for string, provevelmente veio de um rejection de uma promise
    if (typeof error === 'string') {
      this.errorMessage = error;
    } else {
      this.errorMessage = error.message || error.rejection || 'Erro nao tratado. Valor "error.message" esta nulo';
    }

    this.errorStack = error.stack;
    this.errorCode = error.code;
    this.errorName = error.name;
  }

  private handleObservableError(error: any): void {
    this.errorType = 'Observable Error';
    this.userMessage = error.error;
    this.errorMessage = 'ErrorObservable: ' + error.error;
    this.errorName = 'ErrorObservable';
    this.errorStack = null;
    this.errorCode = null;
  }

  private showUserErrorMessage(): void {
    this.showGlobalError(this.userMessage);
  }

  private consoleLogError(error: any): void {
    if (this.env.isCurrentDev) {
      // Console log
      console.log('>>>>> GlobalErrorHandler');
      console.error('_userMessage: ' + this.userMessage);
      console.error('_errorMessage": ' + this.errorMessage);
      console.error('_errorStack": ' + this.errorStack);
      console.error('_errorCode": ' + this.errorCode);
      console.error('_errorName": ' + this.errorName);
      console.error('_errorType": ' + this.errorType);
      console.error('_jsonError": ' + this.jsonError);

      // HandleError padrao do ionic, mostra somente se for desenvolvimento ou homolog
      super.handleError(error);

      console.log('<<<<< GlobalErrorHandler');
    }
  }

  private showGlobalError(erro: any): void {
    this.messageService.showError(erro.message ? erro.message : erro.description);
    if (erro.errors) {
      erro.errors.forEach((e) => this.messageService.showError(e.field + ' ' + e.message));
    }
  }
}
