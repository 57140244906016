import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FlexModule} from "@angular/flex-layout";
import {CommonModule} from "@angular/common";
import {ArtistCircleListComponent} from "./artist-circle-list.component";
import {ArtistCircleModule} from "../artist-circle/artist-circle.module";

@NgModule({
  declarations: [
    ArtistCircleListComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FlexModule,

    //**Components**//
    ArtistCircleModule,

    //**PrimeNG**//

  ],
  exports: [
    ArtistCircleListComponent
  ],
  providers: []
})
export class ArtistCircleListModule {
}
