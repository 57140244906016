import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

export interface Settings {
  hostURL: string;
}

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  get hostURL(): string {
    return environment.hostURL;
  }

  get isCurrentDev(): boolean {
    return !environment.production;
  }

  get isCurrentProd(): boolean {
    return environment.production;
  }

}
