import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { StatusList } from 'src/app/shared/model/product-order';
import { GlobalVarsService } from 'src/app/shared/services/global-vars.service';
import { CoinWS } from 'src/app/shared/services/ws/coin.ws';
import { isEmpty } from 'src/app/shared/utils/strings-utils';
import { MessageService } from './../../../../shared/services/message.service';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.page.html',
  styleUrls: ['./product-card.page.scss'],
})
export class ProductCardPage implements OnInit {
  constructor(
    private confirmationService: ConfirmationService,
    private coinWS: CoinWS,
    private globalVarsService: GlobalVarsService,
    private message: MessageService,
  ) {
    this.changeProductQuantity = new EventEmitter();
    this.removeProduct = new EventEmitter();
  }
  @Input() currentCurrency = 'R$';
  @Input() currentQuote = 1;
  @Input() item: any;
  @Input() showButtonDetails: boolean = false;
  @Input() type: string = ''; // types: 'primary' and 'secondary', default is primary
  @Input() showShoppingCartDetails: boolean = false;
  @Input() productQuantity: number = null;
  @Input() isCategory: boolean = false;
  @Input() productValue: number = null;

  @Output() changeProductQuantity: EventEmitter<any>;
  @Output() removeProduct: EventEmitter<number>;

  isLoading: boolean;
  primaryRouterLink: string[];
  productsImageCarrousel: boolean = false;
  itemsCarrousel = [];
  itemId: number;
  itemName: string;

  atProductQuantityChange(event?: any) {
    if (event <= this.getProductQuantity()) {
      this.productQuantity = event;
    } else if (event > this.getProductQuantity()) {
      this.productQuantity = this.getProductQuantity();
      (<HTMLInputElement>document.getElementById('productQuantity')).value = JSON.stringify(this.getProductQuantity());
    }

    if (this.productQuantity <= this.getProductQuantity()) {
      this.changeProductQuantity.emit({
        id: this.item.id,
        productQuantity: this.productQuantity,
      });
    }
  }

  getProductQuantity(): number {
    return this.item.promotionQuantity
      ? this.item.promotionQuantity - this.item.promotionSoldQuantity
      : this.item.quantity;
  }

  getProductStatus() {
    if (this.item.status) {
      StatusList.map((status) => {
        if (this.item.status === status.value) {
          this.item.status = status.label;
        }
      });

      return this.item.status;
    } else {
      return (this.item.status = 'Status Desconhecido');
    }
  }

  alterProductQuantity(action: string) {
    if (action == 'add' && this.productQuantity + 1 <= this.getProductQuantity()) {
      this.productQuantity = this.productQuantity + 1;
      this.atProductQuantityChange();
    } else if (action == 'subtract' && this.productQuantity > 1) {
      this.productQuantity = this.productQuantity - 1;
      this.atProductQuantityChange();
    } else if (action == 'subtract' && this.productQuantity == 1) {
      this.removeItemFromCart();
    }
  }

  removeItemFromCart() {
    this.confirmationService.confirm({
      message: 'Você tem certeza que deseja remover o produto?',
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.removeProduct.emit(this.item.id);
      },
      reject: () => {},
    });
  }

  ngOnInit(): void {
    this.primaryRouterLink = this.showButtonDetails ? ['/store/add-to-cart/', this.item.id] : [];
  }

  /**
   * using == instead of === to check value after type convertion
   * 0 value from back-end could be string or number
   *
   * @returns true for free freight
   */
  hasFreeFreight() {
    return (
      (isEmpty(this.item.freightValue) || this.item.freightValue == 0) &&
      (isEmpty(this.item.promotionFreightValue) || this.item.promotionFreightValue == 0)
    );
  }

  getPriceMoney() {
    this.item.promotionValue ? this.item.promotionValue : this.item.value;
  }

  getPriceCoin() {
    return Number((this.item.promotionValue ? this.item.promotionValue : this.item.value) / this.currentQuote);
  }

  parseValue(value) {
    return Number(value);
  }
}
