import { Base } from './base';

export interface ProductOrder extends Base {
  coinTransactionId: number;
  totalValue: number;
  totalFreightValue: number;
  address: string;
  status: string;
  userId: number;
  statusDescription: string;

  // List of products
  items?: any[];

  // User Data
  username?: string;

  // Transaction Data
  amount?: number;
  fee?: number;

  // Coin Data
  coinId?: number;
  acronym?: string;
  coinName?: string;

  //rewards
  rewardId?: number;
  rewardQuantity?: number;
}

export enum StatusProductOrderEnum {
  opened = 'OPENED',
  readyForDelivery = 'READY-FOR-DELIVERY',
  cancelled = 'CANCELLED',
  billed = 'BILLED',
  dispatched = 'DISPATCHED',
  delivered = 'DELIVERED',
  finished = 'FINISHED',
  executed = 'EXECUTED',
}

export const StatusList = [
  { value: '', label: 'Todos' },
  { value: StatusProductOrderEnum.opened, label: 'Aberto' },
  { value: StatusProductOrderEnum.cancelled, label: 'Cancelada' },
  { value: StatusProductOrderEnum.billed, label: 'Faturada' },
  { value: StatusProductOrderEnum.dispatched, label: 'Despachado' },
  { value: StatusProductOrderEnum.delivered, label: 'Entregue' },
  { value: StatusProductOrderEnum.finished, label: 'Finalizado' },
  { value: StatusProductOrderEnum.readyForDelivery, label: 'Pronto para entrega' },
  { value: StatusProductOrderEnum.executed, label: 'Em análise' },
];
