import { ErrorHandler, LOCALE_ID, NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { LoginTemplateComponent } from './template/login-template/login-template.component';
import { AppTemplateComponent } from './template/app-template/app-template.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { GlobalErrorHandlerService } from './shared/services/global-error-handler.service';
import { HttpErrorInterceptor } from './shared/interceptors/http-error.interceptor';
import { JwtInterceptor } from './shared/interceptors/jwt.interceptor';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { AppRoutingModule } from './app-routing.module';
import { HomeTemplateComponent } from './template/home-template/home-template.component';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SidebarModule } from 'primeng/sidebar';
import { BadgeModule } from 'primeng/badge';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FormsModule } from '@angular/forms';
import { FooterModule } from './components/footer/footer.module';
import { HomeModule } from './pages/public/home/home.module';
import { HeaderModule } from './components/header/header.module';
import { NgxMaskModule } from 'ngx-mask';
import * as Sentry from '@sentry/angular';
import { RedirectGuard } from './shared/guard/redirect.guard';

// TODO verificar a necessidade de alteração
registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [AppComponent, LoginTemplateComponent, AppTemplateComponent, HomeTemplateComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FlexLayoutModule,
    HttpClientModule,
    RouterModule,
    NgxSpinnerModule,
    NgxMaskModule.forRoot(),
    FormsModule,

    //**Components**//
    HeaderModule,
    FooterModule,
    HomeModule,

    //**PrimeNG**//
    ToastModule,
    ConfirmDialogModule,
    SidebarModule,
    BadgeModule,
    SelectButtonModule,
  ],
  exports: [NgxSpinnerModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    ConfirmationService,
    MessageService,
    RedirectGuard,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
